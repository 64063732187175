import { logger } from './code/logger';
import type { DSMLAnalyticApi } from './dsml';
import DSMLApi from './dsml';

const PROP_NAME = '$dsml';

declare global {
  interface Window {
    $dsml: DSMLAnalyticApi;
  }
}

export function setupDsml(): void {
  const dsml = new DSMLApi();

  const writeSuccess = Reflect.defineProperty(window, PROP_NAME, {
    configurable: false,
    writable: false,
    value: dsml,
  });

  if (!writeSuccess) {
    logger.error(new ReferenceError('dsml.js doesnt init properly'));
  }
}

try {
  setupDsml();
} catch (error) {
  if (error instanceof Error) {
    logger.warn(error.message);
  } else {
    logger.error(error);
  }
}
