<template>
  <app-player-button
    :class="$style.button"
    :on-click="onSkipIntroButtonClick"
    tabindex="0"
    :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerTimelinePlayback)"
  >
    {{ $t('media.intro.skipIntro') }}
  </app-player-button>
</template>

<script lang="ts" setup>
import AppPlayerButton from '@PLAYER/player/components/ui/AppPlayerButton.vue';
import {
  SmartTvPlayerNavigatableGroupName,
  useNavigatableMap,
} from '@PLAYER/player/versions/smart/modules/smart-navigation/use-navigatable-map';

defineProps<{
  onSkipIntroButtonClick: VoidFunction;
}>();

const { getNavigationAttribute } = useNavigatableMap();
</script>

<style lang="scss" module>
.button {
  padding: var(--g-spacing-16);
  height: var(--g-spacing-48);
}
</style>
