import type { StorageKeys } from './storage-types';

type LocalStorageKey = StorageKeys | string;

export interface IStorageService {
  getItem<T>(key: LocalStorageKey, defaultValue: T): T;
  getItem<T>(key: LocalStorageKey): T | null;

  setItem<T>(key: LocalStorageKey, value: T): void;

  clearAll(): void;
}

export class StorageService implements IStorageService {
  public getItem<T>(key: LocalStorageKey, defaultValue?: T): T | null {
    const storageData = window.localStorage.getItem(key);

    if (!storageData) {
      return null;
    }

    const json = this.getJson<T>(storageData);

    if (json) {
      return json;
    }

    if (defaultValue) {
      return defaultValue;
    }

    return json ?? (storageData as T);
  }

  public clearAll() {
    window.localStorage.clear();
  }

  public setItem<T>(key: LocalStorageKey, value: T): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  private getJson<T>(item: string): T | null {
    try {
      return JSON.parse(item);
    } catch {
      return null;
    }
  }
}
