import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { VijuPlayer } from '@PLAYER/player/modules/instance/player';
import { inject } from 'vue';

export default function useTrackedPlayers() {
  const trackedPlayers = inject('trackedPlayers') as Set<VijuPlayer>;

  if (!trackedPlayers) {
    throw new UnexpectedComponentStateError('trackedPlayers');
  }

  return trackedPlayers;
}
