import { sendEventsBatch } from '../request/send-events-batch-request';
import { DsmlRequestBodyV2Params } from './dsml-v2-request-transformer';

class EventBuffer {
  private _buffer: DsmlRequestBodyV2Params[] = [];
  private readonly _MAX_BUFFER_SIZE: number = 150;

  public add(event: DsmlRequestBodyV2Params): void {
    if (this._buffer.length >= this._MAX_BUFFER_SIZE) {
      this._buffer.shift();
    }
    this._buffer.push(event);
  }

  public get length(): number {
    return this._buffer.length;
  }

  private _clear(): void {
    this._buffer = [];
  }

  public async sendAll(): Promise<void> {
    await sendEventsBatch(this._buffer);
    this._clear();
  }
}

export const eventBuffer = new EventBuffer();
