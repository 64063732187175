import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { MouseHandler } from '@PLAYER/player/modules/mouse/mouse-handler';
import { inject } from 'vue';

export default function useMouseHandler() {
  const mouse = inject<MouseHandler>('handler.mouse') as MouseHandler;

  if (!mouse) {
    throw new UnexpectedComponentStateError('mouse');
  }

  return mouse;
}
