import { globalSettings, SessionGetters, SessionState, storeToRefs, useSessionStore } from '@package/sdk/src/smarttv';

export default function useSessionVariables() {
  const { _user } = storeToRefs<SessionState, SessionGetters, unknown>(useSessionStore());

  const isAuth = globalSettings.computed(() => Boolean(_user?.value));

  return {
    isAuth,
  };
}
