import { defineStore, getStoreProperty, VuexStoreNamespace } from '@package/sdk/src/smarttv';

import type { RemoteControlPlaylistItem } from '@/services/remote-control/rc-event';

export interface RemoteControlState {
  remoteControlPlaylist: RemoteControlPlaylistItem[];
  currentRemoteControlProfileId: string;
  currentRemoteControlPlaylistContentId: string;
  remoteControlOffset: number;
}

export interface MainPageActionsWithoutStoreArgument {
  setRemoteControlPlaylist(playlist: RemoteControlPlaylistItem[]): void;

  setCurrentRemoteControlProfileId(id: string): void;

  setCurrentRemoteControlPlaylistContentId(id: string): void;

  setRemoteControlOffset(offset: number): void;
}

export const useRemoteControlStore = defineStore<
  RemoteControlState,
  unknown,
  MainPageActionsWithoutStoreArgument,
  VuexStoreNamespace.RemoteControl
>(VuexStoreNamespace.RemoteControl, {
  state: (): RemoteControlState => ({
    remoteControlPlaylist: [],
    remoteControlOffset: 0,
    currentRemoteControlProfileId: '',
    currentRemoteControlPlaylistContentId: '',
  }),
  actions: {
    setRemoteControlOffset(store, offset) {
      store.commit(getStoreProperty(VuexStoreNamespace.RemoteControl, '$patch'), { remoteControlOffset: offset });
    },
    setRemoteControlPlaylist(store, playlist) {
      store.commit(getStoreProperty(VuexStoreNamespace.RemoteControl, '$patch'), { remoteControlPlaylist: playlist });
    },
    setCurrentRemoteControlProfileId(store, id) {
      store.commit(getStoreProperty(VuexStoreNamespace.RemoteControl, '$patch'), { currentRemoteControlProfileId: id });
    },
    setCurrentRemoteControlPlaylistContentId(store, id) {
      store.commit(getStoreProperty(VuexStoreNamespace.RemoteControl, '$patch'), {
        currentRemoteControlPlaylistContentId: id,
      });
    },
  },
});
