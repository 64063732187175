import '@package/dsml-js/src/core/index';
import 'normalize.css';
import './styles/app.scss';
import './polyfills';
import '@package/ui/src/styles/style.css';
import '@package/ui/src/styles/custom-normalize.scss';

import { SmartTvVijuPlayer } from '@package/media-player/src/player';
import { AnalyticEventNameNew, AnalyticPageName } from '@package/sdk/src/analytics';
import {
  AlertMessageTypes,
  alertService,
  analyticService,
  appNavigationService,
  authService,
  deviceService,
  environmentService,
  globalSettings,
  i18n,
  logger,
  longPressDirective,
  OperationSystem,
  RouterPage,
  routerService,
  setupSentry,
  telemetry,
  useContentStore,
  useSessionStore,
} from '@package/sdk/src/smarttv';
import SmartTvCriticalErrorView from '@package/smarttv-base/src/components/errors/SmartTvCriticalErrorView.vue';
import * as Sentry from '@sentry/vue';
import Axios, { isCancel } from 'axios';
import cryptoJs from 'crypto-js';
import * as dateFns from 'date-fns';
import { ru } from 'date-fns/locale';
import JwtDecode from 'jwt-decode';
import * as QRCode from 'qrcode';
import { v4 as uuidv4 } from 'uuid';
import { computed, createApp, inject, nextTick, onMounted, onUnmounted, ref } from 'vue';
import * as router from 'vue-router';
import Vuex from 'vuex';

import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import { isSafari } from '@/sdk/base/dom';
import { remoteControlService } from '@/services/remote-control/remote-control-service';

import App from './App.vue';
import routes from './services/router/routes';

dateFns.setDefaultOptions({ locale: ru });

const LOG_LEVEL = 6;

const setupLoggers = () => {
  window.$vijuPlayer?.logger?.setLevel(LOG_LEVEL);
  logger.setLevel(LOG_LEVEL);
};

const setupPlayer = () => {
  SmartTvVijuPlayer.setCurrency('₽');
  SmartTvVijuPlayer.setLang('ru');
};

const showAppVersion = () => {
  console.info(
    '%c Viju smarttv version: ' + '%c '.concat(environmentService.getVariable<() => string>('appVersion')() || '', ' '),
    'color: #fff; background: #0A1E1F',
    'color: #fff; background: #cc6666',
  );
};

const hideDebugData = () => {
  let debug = document.getElementById('debug1');

  if (debug) {
    debug.hidden = true;
  }

  debug = document.getElementById('debug2');
  if (debug) {
    debug.hidden = true;
  }

  debug = document.getElementById('debug3');
  if (debug) {
    debug.hidden = true;
  }
};

const setupGlobalSettings = () => {
  globalSettings.axios = Axios;
  globalSettings.axiosIsCancel = isCancel;
  globalSettings.computed = computed;
  globalSettings.ref = ref;
  globalSettings.onMounted = onMounted;
  globalSettings.vuex = Vuex;
  globalSettings.router = router;
  globalSettings.vueVersion = 'vue3';
  globalSettings.routes = routes;
  globalSettings.nextTick = nextTick;
  globalSettings.jwt = JwtDecode;
  globalSettings.dateFns = dateFns;
  globalSettings.qrcode = QRCode;
  globalSettings.uuidv4 = uuidv4;
  globalSettings.cryptoJs = cryptoJs;
  globalSettings.inject = inject;
  globalSettings.onUnmounted = onUnmounted;
  globalSettings.sentry = Sentry;
};

setupGlobalSettings();

const showNonChromiumBrowserNotification = () => {
  if (deviceService.os === OperationSystem.Desktop && isSafari) {
    alertService.addAlert({
      type: AlertMessageTypes.Warning,
      timeoutMs: 100000,
      message: 'Для корректной работы приложения используйте Google Chrome',
    });
  }
};

let isInitialized = false;

let runningTimeoutId: number;

async function main() {
  if (runningTimeoutId) {
    window.clearTimeout(runningTimeoutId);
  }

  if (isInitialized) {
    return;
  }

  environmentService.init({});
  // Что-то старое осталось, пока страшно выпиливать
  hideDebugData();
  // Выставляем env переменные плеера
  setupPlayer();
  // Выставляем логгеры
  setupLoggers();
  // В логи кидаем инфу о версии приложения
  showAppVersion();

  // Инициализируем системные API
  await deviceService.init();
  // активируем навигацию
  appNavigationService.init();
  alertService.initialize();
  // активируем хуки авторизации / род. контроля.
  routerService.initialize();
  authService.init();

  showNonChromiumBrowserNotification();

  remoteControlService.initialize();

  try {
    await analyticService.init();
  } catch (e) {
    //
  }

  const app = createApp(App);

  globalSettings.vue = app;
  app.config.globalProperties.$RouterPage = RouterPage;

  const { router } = routerService;

  setupSentry(app, router, Sentry);

  app.use(router).use(i18n).use(telemetry);

  const contentStore = useContentStore();
  const sessionStore = useSessionStore();

  try {
    const user = await sessionStore.fetchUser({ forceFetchUser: true });

    if (user) {
      const uid = user.currentDevice.uid;

      await remoteControlService.createSession(uid);
    }
  } catch (error) {
    console.error(error);
  }

  await contentStore.fetchGenres();
  await contentStore.fetchPeriods();
  await contentStore.fetchCountries();
  await sessionStore.fetchOffers();

  try {
    analyticService.sendEvent({ name: AnalyticEventNameNew.AutoStartSession, page: AnalyticPageName.Main });
  } catch (e) {
    //
  }

  app.component('NavigatableItem', NavigatableItem);
  app.directive('long-press', longPressDirective);

  app.mount('#app');
  isInitialized = true;
}

const onError = (error: Error) => {
  const app = createApp(SmartTvCriticalErrorView, { error });

  app.mount('#app');
};

const run = () => {
  main().catch((error) => {
    Sentry.captureException(error);
    onError(error);
    run();
  });
};

function getTizenVersion() {
  try {
    const agents = navigator.userAgent.split(' ');

    for (let i = 0; i < agents.length; i++) {
      if (agents[i].substring(0, 5) === 'Tizen') {
        return parseFloat(agents[i + 1]);
      }
    }

    return 0;
  } catch (error) {
    return 0;
  }
}

const tizenVersion = getTizenVersion() as number;
const os = deviceService.getOSInformation();

document.addEventListener('DOMContentLoaded', () => run());

if (os === OperationSystem.WebOs) {
  runningTimeoutId = window.setTimeout(() => run(), 1000);
}

if (tizenVersion >= 4 && tizenVersion !== 5) {
  runningTimeoutId = window.setTimeout(() => run(), 1000);
}
