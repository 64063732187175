import { Channel } from '../../api/channels/types/channel';
import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import { AnalyticDirectionValue } from './get-kmza-content-type-value';
import { GAValue } from './kmza';

const getBaseTvAnalyticsPayload = (channel: Channel): GAValue[] => {
  return [
    {
      label: AnalyticPayloadLabelName.ChannelTitle,
      value: channel.title,
    },
    {
      label: AnalyticPayloadLabelName.ItemTitle,
      value: channel.currentProgram?.title,
    },
    {
      label: AnalyticPayloadLabelName.ItemType,
      value: channel.currentProgram?.contentType,
    },
  ];
};

export function useTvPageAnalytics() {
  const onShowTvChannelsPage = () => {
    window.$dsmlService.sendAnalyticEvent({ name: AnalyticEventNameNew.ShowTvPage, page: AnalyticPageName.Tv });
  };

  const onGotoTvChannelsPage = () => {
    window.$dsmlService.sendAnalyticEvent({
      name: AnalyticEventNameNew.GotoTvPage,
      page: window.$dsmlService.getCurrentAnalyticPageValue(),
    });
  };

  const onGoToTvChannelPage = (item: Channel) => {
    const itemId = item.currentProgram?.contentId as string;
    const episodeId = item.currentProgram?.id;

    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoTvChannelPage,
      page: window.$dsmlService.getCurrentAnalyticPageValue(),
      values: getBaseTvAnalyticsPayload(item),
      options: {
        itemId,
        ...(episodeId && { episodeId }),
      },
    });
  };

  const onShowTvChannelPage = (item: Channel) => {
    const itemId = item.currentProgram?.contentId as string;

    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowTVChannelPage,
      page: window.$dsmlService.getCurrentAnalyticPageValue(),
      values: getBaseTvAnalyticsPayload(item),
      options: {
        itemId,
      },
    });
  };

  const onClickTvPromoSwiped = (direction: AnalyticDirectionValue) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickTvPromoBeltScroll,
      page: AnalyticPageName.Tv,
      values: [
        {
          label: AnalyticPayloadLabelName.Direction,
          value: direction,
        },
      ],
    });
  };

  const onClickTvBeltSwiped = (direction: AnalyticDirectionValue) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickTvBeltScroll,
      page: AnalyticPageName.Tv,
      values: [
        {
          label: AnalyticPayloadLabelName.Direction,
          value: direction,
        },
      ],
    });
  };

  return {
    onShowTvChannelsPage,
    onGotoTvChannelsPage,
    onGoToTvChannelPage,
    onShowTvChannelPage,
    onClickTvPromoSwiped,
    onClickTvBeltSwiped,
  };
}
