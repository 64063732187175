<template>
  <root-video-z-index-layer :class="$style.error" fullscreen>
    <div :class="$style.wrapper">
      <icon-critical-error :height="50" :width="50" />
      <span>
        {{ title }}
      </span>
      <span v-if="errorText">
        {{ errorText }}
      </span>
      <div :class="$style.buttons">
        <app-button
          v-if="isReloadPageShown"
          :on-click="onReloadPageButtonClick"
          variation="button-primary"
          static-animation
          :text="$t('error.reloadPage')"
        />
        <app-button
          v-else
          :on-click="onReloadButtonClick"
          variation="button-secondary"
          static-animation
          :text="$t('error.reloadPlayer')"
        />
        <app-button
          v-if="isWeb && text"
          :on-click="onCopyButtonClick"
          variation="button-secondary"
          static-animation
          :text="isCopied ? $t('common.copy') : $t('error.copyErrorText')"
        />
      </div>
    </div>
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import { copyToClipboard } from '@package/sdk/src/core';
import IconCriticalError from '@PLAYER/player/components/icons/IconCriticalError.vue';
import AppButton from '@PLAYER/player/components/ui/AppButton.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import { VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { translate } from '@PLAYER/player/modules/localization/translate';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

const props = defineProps<{
  title: string;
  text?: string;
  showReloadPageNotification: boolean;
}>();

const { isWeb } = usePlatform();

const isCopied = ref(false);
const eventBus = useSafeEventBus();

const manifestStore = useManifestStore();
const { manifestUrl } = storeToRefs(manifestStore);

const isReloadPageShown = computed(() => isWeb && (props.showReloadPageNotification || !manifestUrl.value));

const onReloadPageButtonClick = () => {
  window.location.reload();
};

const errorText = computed(() => {
  if (isReloadPageShown.value) {
    return translate('error.reloadPageText');
  }

  return props.text;
});

const onReloadButtonClick = () => {
  // Это чтобы зафорсить обновление компонента <hls-initializer-view>
  const src = manifestUrl.value as string;

  eventBus.emit(
    'onSourceUpdated',
    new VideoPlayerInternalEvent({
      src,
    }),
  );
};

const onCopyButtonClick = () => {
  if (!props.text) {
    return;
  }

  copyToClipboard(props.text);
  isCopied.value = true;
};
</script>

<style lang="scss" module>
.error {
  z-index: var(--z-index-error-view);
  display: flex;
  align-items: center;
  background-color: var(--color-bg-primary);
  color: var(--color-notheme-text-primary);
  justify-content: center;
  flex-direction: column;
}

.wrapper {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
}

.buttons {
  display: flex;
  gap: 10px;
}
</style>
