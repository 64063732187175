import AppEvent, { AppEventType } from '../../core/event/event';
import { EventEmitter } from '../../core/event/event-emitter';
import type { IDisposable } from '../../core/lifecycle/disposable';
import { Disposable } from '../../core/lifecycle/disposable';
import { telemetryService } from '../services';

type CommonPointerEvent = MouseEvent | TouchEvent | PointerEvent;

export class AppMouseEvent extends AppEvent {
  constructor(public readonly domEvent: CommonPointerEvent) {
    super();
  }

  public get type(): AppEventType {
    return 'mouse';
  }

  public preventDefault(): void {
    this.domEvent.preventDefault();
    super.preventDefault();
  }

  public toString() {
    const { type } = this.domEvent;

    return `Event: ${type}`;
  }
}

export class AppWheelEvent extends AppEvent {
  constructor(public readonly domEvent: WheelEvent) {
    super();
  }

  public get type(): AppEventType {
    return 'wheel';
  }

  public preventDefault(): void {
    this.domEvent.preventDefault();
    super.preventDefault();
  }

  public toString() {
    const { type } = this.domEvent;

    return `Event: ${type}`;
  }
}

interface MouseEventMap {
  wheel: AppWheelEvent;
}

class MouseEventHandler extends Disposable {
  private readonly emitter: EventEmitter<MouseEventMap> = new EventEmitter();

  constructor() {
    super();
    this.init();
  }

  public on<T extends keyof MouseEventMap>(event: T, listener: (arg: MouseEventMap[T]) => void): IDisposable {
    return this.emitter.on(event, listener);
  }

  private onWheel = (event: WheelEvent): void => {
    const e = new AppWheelEvent(event);

    this.emitter.emit('wheel', e);
  };

  private init(): void {
    window.addEventListener('wheel', this.onWheel, { passive: false });
  }

  public dispose() {
    window.removeEventListener('wheel', this.onWheel);
    this.emitter.dispose();
  }

  public processTelemetryWheelEvent(mouseEvent: WheelEvent): void {
    const appEvent = new AppWheelEvent(mouseEvent);

    telemetryService.trackUserEvent(appEvent);
  }

  public processTelemetryMouseEvent(mouseEvent: CommonPointerEvent): void {
    const appEvent = new AppMouseEvent(mouseEvent);

    telemetryService.trackUserEvent(appEvent);
  }
}

export const mouseEventHandler = new MouseEventHandler();
