import { DsmlValue } from '../request/abstract-send-event-request';

interface DsmlAdditionalOptions {
  itemId?: string;
  timecode?: number;
}

interface LoggerType {
  log: (name: string, page: string, values?: DsmlValue[], payload?: DsmlAdditionalOptions) => void;
  warn: (error: unknown) => void;
  error: (error: unknown) => void;
}

interface LoggerTypeClass extends LoggerType {
  set(logger: LoggerType): void;
}

class Logger implements LoggerTypeClass {
  private _logger: LoggerType | undefined;

  public set(logger: LoggerType): void {
    this._logger = logger;
  }

  public log(name: string, page: string, values?: DsmlValue[], payload?: DsmlAdditionalOptions): void {
    this._logger?.log(name, page, values, payload);
  }

  public warn(error: unknown): void {
    this._logger?.warn(error);
  }

  public error(error: unknown): void {
    this._logger?.error(error);
  }
}

export const logger = new Logger();
