import { AlertMessageTypes, alertService, logger, RouterPage, translate } from '@package/sdk/src/smarttv';
import { defineComponent } from 'vue';
import type * as router from 'vue-router';

const handleDynamicError = (e: Error) => {
  console.error(e);
  logger.debug(e.message);
  logger.debug(e.stack || '');

  alertService.addAlert({
    hideIcon: false,
    type: AlertMessageTypes.Success,
    timeoutMs: 15000,
    message: translate('error.moduleLoadError'),
  });
};

const routes: router.RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: RouterPage.MainPage },
  },
  {
    path: '/',
    component: defineComponent({
      template: '<router-view/>',
    }),
  },
  {
    path: '/main',
    name: RouterPage.MainPage,
    component: () => import('@/pages/main/MainPage.vue').catch(handleDynamicError),
  },
  {
    path: '/search',
    name: RouterPage.SearchPage,
    component: () => import('@/pages/search/SearchPage.vue').catch(handleDynamicError),
  },
  {
    path: '/catalog/',
    name: RouterPage.CatalogPage,
    component: () => import('@/pages/catalog/CatalogPage.vue').catch(handleDynamicError),
  },
  {
    path: '/collection/:page?/:size?/:id?/:type?/',
    name: RouterPage.CollectionPage,
    props: true,
    component: () => import('@/pages/collection/CollectionPage.vue').catch(handleDynamicError),
  },
  {
    path: '/channels/',
    name: RouterPage.ChannelsPage,
    component: () => import('@/pages/channels/ChannelsPage.vue').catch(handleDynamicError),
  },
  {
    path: '/channels/player/:id/',
    name: RouterPage.ChannelsPlayerPage,
    component: () => import('@/pages/channels/player/ChannelsPlayerPage.vue').catch(handleDynamicError),
  },
  {
    path: '/my-channel/:page?/:size?/:id?/:type?/',
    name: RouterPage.MyChannelPage,
    props: true,
    component: () => import('@/pages/my-channel/MyChannelPage.vue').catch(handleDynamicError),
  },
  {
    path: '/moments/:page?/:size?/:id?/:type?/',
    name: RouterPage.MomentsPage,
    props: true,
    component: () => import('@/pages/my-channel/MomentsPage.vue').catch(handleDynamicError),
  },
  {
    path: '/offers/',
    name: RouterPage.Offers,
    component: () => import('@/pages/offers/OffersPage.vue').catch(handleDynamicError),
    beforeEnter: [],
  },
  {
    path: '/offer-info/:offerId/',
    name: RouterPage.OfferInfo,
    props: true,
    component: () => import('@/pages/offers/OfferInfoPage.vue').catch(handleDynamicError),
    beforeEnter: [],
  },
  {
    path: '/debug/device/',
    name: RouterPage.DeviceInfo,
    props: true,
    component: () => import('@/pages/debug/DeviceInfoPage.vue').catch(handleDynamicError),
  },
  {
    path: '/debug/tv-remote/',
    name: RouterPage.DebugTvRemotePage,
    props: true,
    component: () => import('@/pages/debug/TvRemotePage.vue').catch(handleDynamicError),
  },
  {
    path: '/debug/log/',
    name: RouterPage.LogInfo,
    props: true,
    component: () => import('@/pages/debug/LogInfoPage.vue').catch(handleDynamicError),
  },
  {
    path: '/settings/',
    name: RouterPage.SettingsPage,
    component: () => import('@/pages/settings/SettingsPage.vue').catch(handleDynamicError),
    beforeEnter: [],
    redirect: {
      name: RouterPage.PersonalData,
    },
    children: [
      {
        path: 'subscriptions/',
        name: RouterPage.Subscriptions,
        beforeEnter: [],
        redirect: {
          name: RouterPage.UserSubscriptions,
        },
        children: [
          {
            path: 'all/',
            name: RouterPage.UserSubscriptions,
            component: () => import('@/pages/settings/components/UserSubscriptions.vue').catch(handleDynamicError),
          },
          {
            path: 'device-info/?offerId=:offerId/',
            name: RouterPage.SubscriptionInfo,
            props: true,
            component: () => import('@/components/subscription-info/SubscriptionInfo.vue').catch(handleDynamicError),
          },
        ],
      },
      {
        path: 'personal/',
        name: RouterPage.PersonalData,
        component: () => import('@/pages/settings/components/PersonalData.vue').catch(handleDynamicError),
      },
      {
        path: 'help/',
        name: RouterPage.HelpContacts,
        component: () => import('@/pages/settings/components/HelpContacts.vue').catch(handleDynamicError),
      },
      {
        path: 'parental/',
        name: RouterPage.Parental,
        redirect: {
          name: RouterPage.ParentalControl,
        },
        children: [
          {
            path: 'control/:state?/',
            name: RouterPage.ParentalControl,
            props: true,
            component: () =>
              import('@/pages/settings/components/parental-control/ParentalControl.vue').catch(handleDynamicError),
          },
          {
            path: 'rating/?ageLimit=:ageLimit&state=:state&withPhoneNumber=:withPhoneNumber/',
            name: RouterPage.ParentalRating,
            props: true,
            component: () =>
              import('@/pages/settings/components/parental-control/ParentalRating.vue').catch(handleDynamicError),
          },
          {
            path: 'code/?ageLimit=:ageLimit&state=:state&withPhoneNumber=:withPhoneNumber/',
            name: RouterPage.ParentalCode,
            props: true,
            component: () =>
              import('@/pages/settings/components/parental-control/ParentalCode.vue').catch(handleDynamicError),
          },
          {
            path: 'recover/',
            name: RouterPage.ParentalCodeRecover,
            props: true,
            component: () =>
              import('@/pages/settings/components/parental-control/ParentalCodeRecover.vue').catch(handleDynamicError),
          },
        ],
      },
    ],
  },
  {
    path: '/profiles/',
    name: RouterPage.ProfilesPage,
    component: () => import('@/pages/profile/ProfilesPage.vue').catch(handleDynamicError),
    beforeEnter: [],
  },
  {
    path: '/profile/:id?/',
    name: RouterPage.ProfilePage,
    props: true,
    component: () => import('@/pages/profile/ProfilePage.vue').catch(handleDynamicError),
    beforeEnter: [],
  },
  {
    path: '/auth/',
    name: RouterPage.AuthPage,
    component: () => import('@/pages/auth/AuthPage.vue').catch(handleDynamicError),
  },

  {
    path: '/auth/login',
    name: RouterPage.LoginPage,
    component: () => import('@/pages/auth/LoginPage.vue').catch(handleDynamicError),
  },
  {
    path: '/auth/sign-in',
    name: RouterPage.SignInPage,
    component: () => import('@/pages/auth/SignInPage.vue').catch(handleDynamicError),
  },
  {
    path: '/auth/sign-up',
    name: RouterPage.SignUpPage,
    component: () => import('@/pages/auth/SignUpPage.vue').catch(handleDynamicError),
  },
  {
    path: '/auth/recover-code',
    name: RouterPage.RecoverCodePage,
    component: () => import('@/pages/auth/RecoverCodePage.vue').catch(handleDynamicError),
  },
  {
    path: '/auth/recover-password',
    name: RouterPage.RecoverPasswordPage,
    component: () => import('@/pages/auth/RecoverPasswordPage.vue').catch(handleDynamicError),
  },

  {
    path: '/media-card/player/:id/:type/',
    name: RouterPage.MediaCardPlayerPage,
    component: () => import('@/pages/media-card/MediaCardPlayer.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/collections/',
    name: RouterPage.MediaCardCollectionsPage,
    component: () => import('@/pages/media-card/MediaCardCollectionsPage.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/collections/:collectionId/',
    name: RouterPage.MediaCardCollectionsInfoPage,
    component: () => import('@/pages/media-card/MediaCardCollectionsInfoPage.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/:activeEpisodeIndex?/:activeSeasonIndex?',
    name: RouterPage.MediaCardPage,
    component: () => import('@/pages/media-card/MediaCardPage.vue').catch(handleDynamicError),
  },
];

export default routes;
