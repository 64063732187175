import type { DSMLAnalyticApi, SendEventOptions } from '@package/dsml-js/src/core/dsml';
import {
  AnalyticEventNameNew,
  AnalyticPageName,
  AnalyticPayloadLabelName,
  DSMLAdditionalOptions,
  GAValue,
} from '@package/sdk/src/analytics';
import { Moment } from '@package/sdk/src/api';
import { coalesce, isFunction } from '@package/sdk/src/core';
import { DsmlValue } from '@package/sdk/src/smarttv/services/analytics/analytic-types';
import type { AnalyticValue } from '@PLAYER/player/modules/analytics/analytics';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import useLogger from '@PLAYER/player/modules/global/use-logger';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { isDefined } from '@vueuse/core';
import { storeToRefs } from 'pinia';

const toDSMLFormat = (values: GAValue[]): DsmlValue[] =>
  values.map(({ label, value }) => ({ property: label as string, value }));

export default function useAnalytics() {
  const logger = useLogger();
  const videoConfig = useVideoConfig();

  const { currentPlaylist, currentPlaylistItem } = storeToRefs(usePlaylistStore());
  const { isSerialContent, content } = useContentInformation();
  const { isVOD, isKinom, isLive } = useProjector();

  const dsmlMetadata = videoConfig['analytic.metadata'];

  const patchPayloadContent = (values: AnalyticValue[]) => {
    // Если kinom
    if (isKinom.value) {
      values.push({ label: AnalyticPayloadLabelName.KinomTitle, value: content.value.title });
      values.push({ label: AnalyticPayloadLabelName.ItemTitle, value: (content.value as Moment).contentTitle });
    }

    if (isLive.value) {
      values.push({ label: AnalyticPayloadLabelName.ChannelTitle, value: content.value.title });
      values.push({ label: AnalyticPayloadLabelName.ItemType, value: 'tvchannel' });
    }

    // если VOD плеер
    if (isVOD.value) {
      values.push({ label: AnalyticPayloadLabelName.ItemType, value: isSerialContent.value ? 'series' : 'movie' });
      values.push({ label: AnalyticPayloadLabelName.ItemTitle, value: content.value.title });

      if (isSerialContent.value && isDefined(currentPlaylistItem.value)) {
        values.push({ label: AnalyticPayloadLabelName.Season, value: currentPlaylist.value.number });
        values.push({ label: AnalyticPayloadLabelName.Episode, value: currentPlaylistItem.value.number });
      }
    }
  };

  type SendAnalyticFunc = (params: {
    page?: AnalyticPageName;
    values?: AnalyticValue[];
    options?: DSMLAdditionalOptions;
    name: AnalyticEventNameNew;
  }) => void;

  const sendAnalytic: SendAnalyticFunc = ({ name, page, values, options = {} }) => {
    const _page = dsmlMetadata?.page || page;
    const _values: AnalyticValue[] = isDefined(values) ? values : [];

    const itemId = (() => {
      if (isVOD.value) {
        return content.value.id;
      }

      if (isKinom.value) {
        return (content.value as Moment).contentId;
      }

      return undefined;
    })();

    const kinomId = isKinom.value ? content.value.id : undefined;
    const episodeId =
      isSerialContent.value && isDefined(currentPlaylistItem.value) ? currentPlaylistItem.value.id : undefined;

    const baseOptions: DSMLAdditionalOptions = {
      itemId,
      ...(kinomId && { kinomId }),
      ...(episodeId && { episodeId }),
    };

    const _options: DSMLAdditionalOptions = isDefined(options)
      ? Object.assign<DSMLAdditionalOptions, DSMLAdditionalOptions>(options, baseOptions)
      : baseOptions;

    patchPayloadContent(_values);

    try {
      const event: SendEventOptions = { name, page: _page, values: toDSMLFormat(coalesce(_values)), options: _options };

      logger.info('Player#sendAnalytic', JSON.stringify(event));

      if (!isFunction(window.$dsml?.sendEvent)) {
        return;
      }

      const dsml = window.$dsml as DSMLAnalyticApi;

      dsml.sendEvent(event);
    } catch (e) {
      logger.error(e);
    }
  };

  return {
    sendAnalytic,
  };
}
