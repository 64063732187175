<template>
  <div :class="$style.controls" :aria-label="$t('controls.elements')">
    <div :class="$style.wrapper">
      <div :class="$style.control">
        <video-state-element
          v-if="isVideoStateElementShown"
          :is-playing="isPlaying || isShouldPlayVideoAfterRewind"
          :is-background-shown="false"
          :is-text-shown="false"
          icon-size="large"
          :on-play-button-click="playbackActions.doPlay"
          :on-pause-button-click="playbackActions.doPause"
        />
      </div>
      <template v-if="isEpisodesNavigationControlsShown">
        <smart-tv-next-episode-element v-if="!isCurrentEpisodeLastAvailable" :class="$style.control" />
        <smart-tv-episodes-list-element :class="$style.control" />
      </template>
      <smart-tv-quality-element v-if="isQualityLevelEnabled" :class="$style.control" />
      <smart-tv-speed-playback-element v-if="isPlaybackRateElementShown" :class="$style.control" />
      <smart-tv-debug-element v-if="isDev" :class="$style.control" />
      <div :class="$style.control">
        <live-detector-view
          v-if="isLiveDetectorControlShown"
          :is-current-time-live="isCurrentTimeLive"
          :on-seek-to-live="playbackRewindActions.goToLive"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isDev } from '@PLAYER/player/base/dom';
import LiveDetectorView from '@PLAYER/player/components/controls/LiveDetectorView.vue';
import VideoStateElement from '@PLAYER/player/components/video/VideoStateElement.vue';
import useEpisodesNavigationActions from '@PLAYER/player/modules/hooks/use-episodes-navigation-actions';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import usePlaybackActions from '@PLAYER/player/modules/video/use-playback-actions';
import usePlaybackRewindActions from '@PLAYER/player/modules/video/use-playback-rewind-actions';
import usePlayerSettings from '@PLAYER/player/modules/video/use-player-settings';
import useVideoControlsVariables from '@PLAYER/player/modules/video/use-video-controls-variables';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import SmartTvDebugElement from '@PLAYER/player/versions/smart/components/controls/SmartTvDebugElement.vue';
import SmartTvEpisodesListElement from '@PLAYER/player/versions/smart/components/controls/SmartTvEpisodesListElement.vue';
import SmartTvNextEpisodeElement from '@PLAYER/player/versions/smart/components/controls/SmartTvNextEpisodeElement.vue';
import SmartTvQualityElement from '@PLAYER/player/versions/smart/components/controls/SmartTvQualityElement.vue';
import SmartTvSpeedPlaybackElement from '@PLAYER/player/versions/smart/components/controls/SmartTvSpeedPlaybackElement.vue';
import { storeToRefs } from 'pinia';

const {
  isEpisodesNavigationControlsShown,
  isPlaybackRateElementShown,
  isVideoStateElementShown,
  isLiveDetectorControlShown,
} = useVideoControlsVariables();
const { isCurrentEpisodeLastAvailable } = useEpisodesNavigationActions();
const { isCurrentTimeLive } = useVideoPlayerVariables();
const { isQualityLevelEnabled } = usePlayerSettings();
const { isPlaying } = storeToRefs(useVideoUIStore());
const { isShouldPlayVideoAfterRewind } = storeToRefs(useVideoControlsStore());

const playbackRewindActions = usePlaybackRewindActions();
const playbackActions = usePlaybackActions();
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.controls {
  display: flex;
  flex-direction: column;
}

.control {
  position: relative;
}

.wrapper {
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
  width: 100%;

  & > .control {
    margin-right: adjust.adjustPx(16px);

    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
