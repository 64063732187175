<template></template>

<script lang="ts" setup>
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useSafeGetMediaSourceTech from '@PLAYER/player/modules/hooks/use-safe-get-media-source-tech';

const getMediaSourceTech = useSafeGetMediaSourceTech();

const onSubtitleTrackLoaded = () => {
  //
};

const onSubtitleTracksUpdated = () => {
  //
};

useEffect(() => {
  const mediaSourceTech = getMediaSourceTech();

  mediaSourceTech.on('subtitle-track-loaded', onSubtitleTrackLoaded);
  mediaSourceTech.on('subtitle-track-updated', onSubtitleTracksUpdated);

  return () => {
    mediaSourceTech.off('subtitle-track-loaded', onSubtitleTrackLoaded);
    mediaSourceTech.off('subtitle-track-updated', onSubtitleTracksUpdated);
  };
});
</script>
