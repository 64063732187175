import MediaSourceTech, {
  MediaSourceLoadOptions,
  MediaSourceTechBufferInfo,
} from '@PLAYER/player/tech/media-source-tech';

class TataudioMediaInstance {
  public readonly mediaSource: MediaSource;

  constructor() {
    this.mediaSource = new MediaSource();
  }
}

export default class TataudioMediaSourceTech extends MediaSourceTech {
  private tech: TataudioMediaInstance;

  constructor() {
    super();

    this.tech = new TataudioMediaInstance();
  }

  public get audioCodec(): string {
    return '';
  }

  public get bandwidth(): number {
    return 0;
  }

  public get buffer(): MediaSourceTechBufferInfo {
    return {
      length: 0,
      start: 0,
    };
  }

  public get currentQualityLevelHeight(): number {
    return 0;
  }

  public getNextLevel(): number {
    return 0;
  }

  public init(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public get latency(): number {
    return 0;
  }

  public loadSource(_: MediaSourceLoadOptions): void {
    //
  }

  public recoverMediaError(): void {
    //
  }

  protected registerListeners(): void {
    //
  }

  public setNextLevel(_: number): void {
    //
  }

  public startLoad(_: number | undefined): void {
    //
  }

  public stopLoad(): void {
    //
  }

  public get videoCodec(): string {
    return '';
  }
}
