<template></template>

<script lang="ts" setup>
import { toPixel } from '@package/sdk/src/core';
import { isMobile } from '@PLAYER/player/base/dom';
import { VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useLogger from '@PLAYER/player/modules/global/use-logger';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import { useIntersectionObserver } from '@vueuse/core';
import { onBeforeUnmount } from 'vue';

const externalEventBus = useSafeExternalEventBus();
const layoutStore = useLayoutStore();
const logger = useLogger();

const props = defineProps<{
  rootVideoEl: HTMLElement;
}>();

const thresholdValue = isMobile ? 0.5 : 0.9;

const threshold: number[] = [thresholdValue, thresholdValue];

const { stop } = useIntersectionObserver(
  props.rootVideoEl,
  ([{ isIntersecting: intersect }]) => {
    layoutStore.setIsPlayerIntersect(intersect);

    const data = { intersect };

    logger.info('VideoPlayer - intersect', JSON.stringify(data));
    externalEventBus.emit('intersect', new VideoPlayerExternalEvent(data));
  },
  { threshold, root: null, rootMargin: toPixel(0) },
);

onBeforeUnmount(stop);
</script>
