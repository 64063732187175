import { AttrList } from '@PLAYER/player/modules/hls/attr-list';

const MASTER_PLAYLIST_REGEX =
  /#EXT-X-STREAM-INF:([^\r\n]*)(?:[\r\n](?:#[^\r\n]*)?)*([^\r\n]+)|#EXT-X-(SESSION-DATA|SESSION-KEY|DEFINE|CONTENT-STEERING|START):([^\r\n]*)[\r\n]+/g;

export interface M3U8QualityLevel {
  width: number;
  height: number;
  url: string;
}

export interface M3U8MasterPlaylist {
  levels: M3U8QualityLevel[];
}

export class M3U8Parser {
  public static isM3U8ManifestUrl(url: string) {
    return url.includes('.m3u8');
  }

  /**
   * Ручной парсинг манифеста. Пока забираем только качества видео - чтобы выводить их в манифесте
   *
   * @param {string} input
   * @return {M3U8MasterPlaylist}
   */
  public static parseMasterPlaylist(input: string): M3U8MasterPlaylist {
    const levels: M3U8QualityLevel[] = [];

    let result: RegExpExecArray | null;

    while ((result = MASTER_PLAYLIST_REGEX.exec(input)) !== null) {
      const url = result[2];

      const attrs = new AttrList(result[1]);
      const resolution = attrs.decimalResolution('RESOLUTION');

      levels.push({ url, width: resolution?.width || 0, height: resolution?.height || 0 });
    }

    return {
      levels,
    };
  }
}
