import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { inject, Ref, ref } from 'vue';

export default function useSafeRootVideoElement() {
  const rootVideoEl = inject<Ref<HTMLDivElement>>('rootVideoEl');

  if (!rootVideoEl) {
    throw new UnexpectedComponentStateError('rootVideoEl');
  }

  return ref(rootVideoEl.value);
}
