import { Disposable } from '@package/sdk/src/core';
import AbstractMediaTech, {
  MediaSourceLoadOptions,
  MediaSourceTechBufferInfo,
} from '@PLAYER/player/tech/media-source-tech';
import type { AVPlayError, AVPlayEvent, AVPlayPlaybackCallback } from 'tizen-tv-webapis';

class AvPlayInstance extends Disposable implements AVPlayPlaybackCallback {
  constructor() {
    super();
  }

  public onbufferingstart() {
    //
  }

  public onbufferingprogress(_: number) {
    //
  }

  public onbufferingcomplete() {
    //
  }

  public onstreamcompleted() {
    webapis.avplay.stop();
  }

  public oncurrentplaytime(_currentTime: number) {
    const duration = webapis.avplay.getDuration();

    // means its initialized
    if (duration > 0) {
      //
    }
  }

  public onerror(_: AVPlayError) {
    //
  }

  public onevent(_: AVPlayEvent, __: string) {
    //
  }
}

export default class AvplayMediaTech extends AbstractMediaTech {
  constructor() {
    super();
  }

  public get bandwidth(): number {
    return 0;
  }

  public get videoCodec(): string {
    return '';
  }

  public get audioCodec(): string {
    return '';
  }

  public get currentQualityLevelHeight(): number {
    return 0;
  }

  public get latency(): number {
    return 0;
  }

  public loadSource(options: MediaSourceLoadOptions) {
    const { src } = options;

    // html5

    // videoEl.src = src;
    // видео играет!

    // hls
    // const hls = new Hls();
    // hls.attachMedia(videoEl);
    // hls.loadSource(src);
    // видео играет!

    webapis.avplay.open(src);
    webapis.avplay.setDisplayRect(0, 0, 1920, 1080);

    const onSuccess = () => {
      webapis.avplay.play();

      // set duration
      const duration = webapis.avplay.getDuration();
      // duration
    };

    const onError = () => {
      console.error('error avplay');
    };

    webapis.avplay.prepareAsync(onSuccess, onError);
  }

  public getNextLevel(): number {
    return 0;
  }

  public setNextLevel(_: number): void {
    //
  }

  protected registerListeners(): void {
    //
  }

  public get buffer(): MediaSourceTechBufferInfo {
    return { length: 0, start: 0 };
  }

  public init(): Promise<void> {
    const avplayListener = new AvPlayInstance();

    webapis.avplay.setListener(avplayListener);

    return Promise.resolve(undefined);
  }

  public recoverMediaError(): void {
    //
  }

  public startLoad(_ = 0): void {
    //
  }

  public stopLoad(): void {
    //
  }
}
