import { FeatureToggleItem } from '../../../api/feature-toggle/types/feature';
import { FeatureToggle } from '../../../api/feature-toggle/types/feature-toggle';
import { Endpoints } from '../endpoints';
import type { EnvironmentService } from '../environment/environment-service';
import type { RequestService } from '../request-service';

export class FeatureService {
  private featureFlags: FeatureToggleItem[] = [];

  constructor(
    private readonly $http: RequestService,
    private readonly $environment: EnvironmentService,
  ) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public get canEditProfiles() {
    return false;
  }

  public get showForgetForParental() {
    return false;
  }

  public get withMomentsCollection() {
    return false;
  }

  public async getFeatureFlag(name: FeatureToggle): Promise<boolean> {
    try {
      this.featureFlags = await this.getFeatureFlags();

      const flag = this.featureFlags.find((feature) => feature.name === name);

      return Boolean(flag?.status);
    } catch {
      return false;
    }
  }

  private async getFeatureFlags() {
    try {
      const { data } = await this.$http.request<FeatureToggleItem[]>(
        {
          url: Endpoints.Features,
          baseURL: this.$environment.getVariable('apiFlagsBaseURL'),
          params: {
            with_variant: true,
          },
        },
        {
          withToken: true,
          skipTokenValidation: true,
        },
      );

      return data;
    } catch {
      return [];
    }
  }
}
