import { Disposable } from '../lifecycle/disposable';

export type AppEventType = 'keyboard' | 'mouse' | 'wheel';

export default abstract class AppEvent extends Disposable {
  private _defaultPrevented = false;

  protected constructor() {
    super();
  }

  public abstract get type(): AppEventType;

  public get defaultPrevented(): boolean {
    return this._defaultPrevented;
  }

  public preventDefault(): void {
    this._defaultPrevented = true;
  }
}
