import { defineStore } from './utils/define-store';
import { getStoreProperty, VuexStoreNamespace } from './utils/namespaces';

export interface AuthState {
  selectedOfferId: string;
  backUrl: string;
  loginInput: string;
  otpCode: string;
}

interface AuthActionsWithoutStoreArgument {
  setSelectedOfferId(offerId: string): void;

  setBackUrl(url: string): void;

  setLoginInput(value: string): void;

  setOtpCode(value: string): void;

  clearAuthArtifacts(): void;
}

export const useAuthStore = defineStore<AuthState, {}, AuthActionsWithoutStoreArgument, VuexStoreNamespace.Auth>(
  VuexStoreNamespace.Auth,
  {
    state: (): AuthState => ({
      selectedOfferId: '',
      backUrl: '',
      loginInput: '',
      otpCode: '',
    }),
    actions: {
      setSelectedOfferId(store, offerId: string) {
        store.commit(getStoreProperty(VuexStoreNamespace.Auth, '$patch'), { selectedOfferId: offerId });
      },
      setBackUrl(store, url: string) {
        store.commit(getStoreProperty(VuexStoreNamespace.Auth, '$patch'), { backUrl: url });
      },
      setLoginInput(store, value: string) {
        store.commit(getStoreProperty(VuexStoreNamespace.Auth, '$patch'), { loginInput: value });
      },
      setOtpCode(store, value: string) {
        store.commit(getStoreProperty(VuexStoreNamespace.Auth, '$patch'), { otpCode: value });
      },
      clearAuthArtifacts(store) {
        store.commit(getStoreProperty(VuexStoreNamespace.Auth, '$patch'), { loginInput: '', otpCode: '' });
      },
    },
  },
);
