<template>
  <div
    :class="{
      [$style.bar]: true,
      [$style.active]: isActive,
      [$style.backgroundProgressBar]: showBackgroundProgress,
    }"
    :style="{ width: $toPixel(width) }"
  >
    <div
      v-if="showBackgroundProgress"
      :class="$style.backgroundProgress"
      :style="{ width: $toPercent(backgroundWidth) }"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { secondToPixel } from '@PLAYER/player/modules/timeline/coordinates';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    title: string | null;
    startOffset: number; // seconds
    endOffset: number; // seconds
    pixelFactor: number;
    currentTime: number;
    showBackgroundProgress: boolean;
  }>(),
  {
    showBackgroundProgress: false,
  },
);

const startOffsetPx = computed(() => secondToPixel(props.startOffset, props.pixelFactor));
const endOffsetPx = computed(() => secondToPixel(props.endOffset, props.pixelFactor));
const width = computed(() => endOffsetPx.value - startOffsetPx.value);

const isActive = computed(() => {
  const currentTime = props.currentTime;

  if (currentTime === 0) {
    return false;
  }

  return currentTime > props.startOffset;
});

const backgroundWidth = computed<number>(() => {
  const currentTime = props.currentTime;

  if (props.startOffset > currentTime) {
    return 0;
  }

  if (props.endOffset < currentTime) {
    return 100;
  }

  const percentValue = (props.endOffset - props.startOffset) / 100;
  const interval = currentTime - props.startOffset;

  return interval / percentValue;
});
</script>

<style lang="scss" module>
.bar {
  position: relative;
  padding: 0;
  height: 4px;
  border-radius: 10px;
  background-color: var(--color-text-secondary);
  margin-right: 4px;
  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: var(--color-notheme-text-primary);
    transform: scaleY(1.5);
  }
}

.backgroundProgressBar {
  z-index: -1;
  background-color: initial;
  pointer-events: none;
}

.backgroundProgress {
  height: 100%;
  background-color: var(--color-notheme-bg-accent);
}

.active {
  &:hover {
    background-color: var(--color-notheme-bg-accent);
  }
}

.empty {
  background-color: var(--color-text-secondary);
  transform: initial;
}
</style>
