import { ApplicationError } from '@package/sdk/src/core';

const DSML_EVENT_ERROR_NAME = 'DsmlEventError';

export class DsmlEventError extends ApplicationError {
  public readonly name = DSML_EVENT_ERROR_NAME;

  constructor(public readonly details: string[]) {
    super();
  }

  public toJSON(): Record<string, any> {
    const { details } = this;

    return {
      details,
    };
  }

  public toString() {
    return this.details.join('\n');
  }
}
