<template>
  <app-label
    :class="{
      [$style.timelineLabel]: true,
      [$style.timelineLabelSmartTv]: isSmartTV,
      [$style.timelineLabelMobile]: $isMobile,
      [$style.timelineFocused]: isFocused,
    }"
    position="absolute"
    :text="displayTime"
    :left="leftPosition"
  />
</template>

<script lang="ts" setup>
import AppLabel from '@PLAYER/player/components/ui/AppLabel.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';

defineProps<{
  leftPosition: number;
  displayTime: string;
  isFocused?: boolean;
}>();

const { isSmartTV } = usePlatform();
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;
@use '@package/ui/src/styles/fonts.scss' as webFonts;

.timelineLabel {
  bottom: var(--g-spacing-20);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--g-spacing-4) var(--g-spacing-8) var(--g-spacing-6) var(--g-spacing-8);
  text-align: center;
}

.timelineFocused.timelineLabelSmartTv {
  border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
}

.timelineLabelSmartTv {
  padding: adjust.adjustPx(28px) adjust.adjustPx(24px);
  width: adjust.adjustPx(165px);
  height: adjust.adjustPx(90px);
  border-radius: var(--g-border-round-16);
  overflow: hidden;

  will-change: left;
}

.timelineLabelMobile {
  @include webFonts.WebLabel-2;
  bottom: var(--g-spacing-24);
  padding: var(--g-spacing-10) var(--g-spacing-8);
}
</style>
