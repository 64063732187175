import { Disposable } from '@package/sdk/src/core';
import { TimeSeconds } from '@PLAYER/player/base/number';
import { UnexpectedPropertyConditionError } from '@PLAYER/player/errors/unexpected-property-condition-error';
import { isClient } from '@vueuse/core';

export interface MediaElementApi {
  readonly videoWidth: number;
  readonly videoHeight: number;
  readonly duration: number;
  readonly paused: boolean;

  playbackRate: number;
  muted: boolean;
  currentTime: TimeSeconds;
  volume: number;

  play(): Promise<void>;

  webkitShowPlaybackTargetPicker(): void;

  pause(): void;

  requestPictureInPicture(): Promise<PictureInPictureWindow>;

  removeEventListener<K extends keyof HTMLElementEventMap>(
    type: K,
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    options?: boolean | EventListenerOptions,
  ): void;

  addEventListener<K extends keyof HTMLElementEventMap>(
    type: K,
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions,
  ): void;
}

export class ObjectElementDOMInstance extends Disposable implements MediaElementApi {
  constructor(private readonly objectEl: HTMLObjectElement) {
    super();
  }

  public get playbackRate() {
    return 0;
  }

  public set playbackRate(_: number) {
    //
  }

  public get paused() {
    return false;
  }

  public get videoHeight() {
    return 0;
  }

  public get videoWidth() {
    return 0;
  }

  public get currentTime() {
    return 0;
  }

  public set currentTime(_: number) {
    //
  }

  public get duration() {
    return 0;
  }

  public get volume() {
    return 0;
  }

  public set volume(_: number) {
    //
  }

  public get muted() {
    return false;
  }

  public set muted(_: boolean) {
    //
  }

  public pause(): void {
    //
  }

  public play(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public async requestPictureInPicture(): Promise<PictureInPictureWindow> {
    return new PictureInPictureWindow();
  }

  public addEventListener<K extends keyof HTMLElementEventMap>(
    type: K,
    listener: (this: HTMLObjectElement, ev: HTMLElementEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions,
  ) {
    return this.objectEl.addEventListener(type, listener, options);
  }

  public removeEventListener<K extends keyof HTMLElementEventMap>(
    type: K,
    listener: (this: HTMLObjectElement, ev: HTMLElementEventMap[K]) => any,
    options?: boolean | EventListenerOptions,
  ) {
    return this.objectEl.removeEventListener(type, listener, options);
  }

  public webkitShowPlaybackTargetPicker(): void {
    //
  }
}

export class VideoElementDOMInstance extends Disposable implements MediaElementApi {
  constructor(private readonly videoEl: HTMLVideoElement) {
    super();
  }

  public get playbackRate() {
    return this.videoEl.playbackRate;
  }

  public set playbackRate(val: number) {
    this.videoEl.playbackRate = val;
  }

  public get videoWidth() {
    return this.videoEl.videoWidth;
  }

  public get videoHeight() {
    return this.videoEl.videoHeight;
  }

  public get muted() {
    return this.videoEl.muted;
  }

  public get paused() {
    return this.videoEl.paused;
  }

  public set muted(val: boolean) {
    this.videoEl.muted = val;
  }

  public get currentTime() {
    return this.videoEl.currentTime;
  }

  public set currentTime(time: TimeSeconds) {
    this.videoEl.currentTime = time;
  }

  public get duration() {
    return this.videoEl.duration;
  }

  public get volume() {
    return this.videoEl.volume;
  }

  public set volume(volume: number) {
    this.videoEl.volume = volume;
  }

  public requestPictureInPicture(): Promise<PictureInPictureWindow> {
    return this.videoEl.requestPictureInPicture();
  }

  public webkitShowPlaybackTargetPicker() {
    return this.videoEl.webkitShowPlaybackTargetPicker();
  }

  public play() {
    return this.videoEl.play();
  }

  public pause() {
    return this.videoEl.pause();
  }

  public addEventListener<K extends keyof HTMLElementEventMap>(
    type: K,
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions,
  ) {
    return this.videoEl.addEventListener(type, listener, options);
  }

  public removeEventListener<K extends keyof HTMLElementEventMap>(
    type: K,
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    options?: boolean | EventListenerOptions,
  ) {
    return this.videoEl.removeEventListener(type, listener, options);
  }
}

export class SsrElementDOMInstance extends Disposable implements MediaElementApi {
  public currentTime: TimeSeconds = 0;
  public readonly duration: number = 0;
  public muted = false;
  public playbackRate = 0;
  public readonly videoHeight: number = 0;
  public readonly videoWidth: number = 0;
  public volume = 0;

  public addEventListener<K extends keyof HTMLElementEventMap>(
    _type: K,
    _listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    _options?: boolean | AddEventListenerOptions,
  ): void {
    //
  }

  public pause(): void {
    //
  }

  public get paused() {
    return false;
  }

  public play(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public removeEventListener<K extends keyof HTMLElementEventMap>(
    _type: K,
    _listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    _options?: boolean | EventListenerOptions,
  ): void {
    //
  }

  public async requestPictureInPicture(): Promise<PictureInPictureWindow> {
    return new PictureInPictureWindow();
  }

  public webkitShowPlaybackTargetPicker(): void {
    //
  }
}

export class MediaElementDOMInstance extends Disposable implements MediaElementApi {
  private readonly domApi: MediaElementApi;

  constructor(public readonly domElement: HTMLVideoElement | HTMLObjectElement) {
    super();

    if (!isClient) {
      this.domApi = new SsrElementDOMInstance();
    } else if (isClient && domElement instanceof HTMLVideoElement) {
      this.domApi = new VideoElementDOMInstance(domElement);
    } else if (isClient && domElement instanceof HTMLObjectElement) {
      this.domApi = new ObjectElementDOMInstance(domElement);
    } else {
      throw new UnexpectedPropertyConditionError(
        'domElement',
        typeof domElement,
        'HTMLVideoElement | HTMLObjectElement',
      );
    }
  }

  public addEventListener<K extends keyof HTMLElementEventMap>(
    type: K,
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions,
  ) {
    return this.domApi.addEventListener(type, listener, options);
  }

  public removeEventListener<K extends keyof HTMLElementEventMap>(
    type: K,
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    options?: boolean | EventListenerOptions,
  ) {
    return this.domApi.removeEventListener(type, listener, options);
  }

  public requestPictureInPicture(): Promise<PictureInPictureWindow> {
    return this.domApi.requestPictureInPicture();
  }

  public get paused() {
    return this.domApi.paused;
  }

  public get videoWidth() {
    return this.domApi.videoWidth;
  }

  public get videoHeight() {
    return this.domApi.videoHeight;
  }

  public get volume() {
    return this.domApi.volume;
  }

  public set volume(val) {
    this.domApi.volume = val;
  }

  public get muted() {
    return this.domApi.muted;
  }

  public set muted(val) {
    this.domApi.muted = val;
  }

  public get currentTime() {
    return this.domApi.currentTime;
  }

  public set currentTime(time: TimeSeconds) {
    this.domApi.currentTime = time;
  }

  public get duration() {
    return this.domApi.duration;
  }

  public get playbackRate() {
    return this.domApi.playbackRate;
  }

  public set playbackRate(val: number) {
    this.domApi.playbackRate = val;
  }

  public pause(): void {
    return this.domApi.pause();
  }

  public play(): Promise<void> {
    return this.domApi.play();
  }

  public webkitShowPlaybackTargetPicker(): void {
    return this.domApi.webkitShowPlaybackTargetPicker();
  }
}
