import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import MediaSourceTech from '@PLAYER/player/tech/media-source-tech';
import { inject } from 'vue';

export default function useSafeGetMediaSourceTech() {
  const getMediaSourceTech = inject<() => MediaSourceTech>('getMediaSourceTech');

  if (!getMediaSourceTech) {
    throw new UnexpectedComponentStateError('getMediaSourceTech');
  }

  return getMediaSourceTech;
}
