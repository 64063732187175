import { isDefined, isFunction, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useExperimentalFeature from '@PLAYER/player/modules/experimental-feature/use-experimental-feature';
import defineGlobalVueProperty from '@PLAYER/player/modules/global/define-global-vue-property';
import getGlobalVueProperty from '@PLAYER/player/modules/global/get-global-vue-property';
import useLogger from '@PLAYER/player/modules/global/use-logger';
import { PlayerGlobalProperty, PlayerInstanceLoadSourceOptions } from '@PLAYER/player/modules/instance/interfaces';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import VideoEventHandler from '@PLAYER/player/modules/video/video-event-handler';
import { getCurrentInstance, nextTick, onBeforeUnmount, provide, ref } from 'vue';

interface Options {
  initialSrc?: string;
}

export default function provideGlobalVideoElements(options: Options) {
  const manifestStore = useManifestStore();
  const eventBus = useSafeEventBus();
  const externalEventBus = useSafeExternalEventBus();
  const logger = useLogger();

  const { isLazyLoadingInteractionEnabled } = useExperimentalFeature();

  const videoUIStore = useVideoUIStore();
  const layoutStore = useLayoutStore();
  const playlistStore = usePlaylistStore();

  const videoEl = ref<HTMLVideoElement | HTMLObjectElement>();
  const labelEl = ref<HTMLElement>();
  const tooltipEl = ref<HTMLElement>();

  const videoEventHandler = new VideoEventHandler();

  provide('videoEl', videoEl);
  provide('labelEl', labelEl);
  provide('tooltipEl', tooltipEl);

  provide('videoEventHandler', videoEventHandler);
  manifestStore.setManifestUrl(options.initialSrc);

  const app = getCurrentInstance();

  const loadSource = (options: PlayerInstanceLoadSourceOptions) => {
    const { src, id } = options;

    if (id) {
      playlistStore.setCurrentPlaylistItemId(id);
    }

    if (!src) {
      throw new UnexpectedComponentStateError('src');
    }

    eventBus.emit('onSourceUpdated', new VideoPlayerInternalEvent(options));
  };

  const setVideoElement = (element: HTMLVideoElement) => {
    if (!element) {
      return;
    }

    const isMountedFunc = getGlobalVueProperty(app, PlayerGlobalProperty.PlayerMounted);
    const isMounted = isFunction(isMountedFunc) && isMountedFunc() === true;

    videoEventHandler.registerListeners(element);
    videoEl.value = element;

    if (isMounted) {
      return;
    }

    defineGlobalVueProperty(app, PlayerGlobalProperty.LoadSource, loadSource);
    defineGlobalVueProperty(app, PlayerGlobalProperty.VideoEl, element);
    defineGlobalVueProperty(app, PlayerGlobalProperty.PlayerMounted, () => true);

    // Emit only on next-tick
    nextTick(() => externalEventBus.emit('mounted'));
  };

  const isInitialLoadPassed = ref(false);

  const onSourceUpdated = (event: VideoPlayerInternalEvent<PlayerInstanceLoadSourceOptions>) => {
    const { src, offset } = event.data;

    logger.debug('onSourceUpdated', {
      ...event.data,
      src: '<HIDDEN>',
    });

    videoUIStore.setIsInitialized(false);

    if (isDefined(offset)) {
      manifestStore.setManifestStartOffset(offset);
    }

    manifestStore.setManifestUrl(src);

    if (isLazyLoadingInteractionEnabled.value) {
      videoUIStore.setIsVideoPreviewShown(false);
    }

    if (isInitialLoadPassed.value) {
      layoutStore.setIsShownPoster(false);
    }

    isInitialLoadPassed.value = true;
  };

  eventBus.on('onSourceUpdated', onSourceUpdated);

  const playingHandler = videoEventHandler.addEventListener('playing', () => {
    layoutStore.setIsShownPoster(false);

    playingHandler.dispose();
  });

  onBeforeUnmount(() => {
    videoEventHandler.dispose();
  });

  return {
    tooltipEl,
    labelEl,
    setVideoElement,
  };
}
