<template>
  <div :class="$style.console">
    <div :class="$style.header">
      <span :class="$style.title">{{ $t('debug.terminal') }} - {{ $t('debug.terminal.console') }}</span>
      <app-checkbox v-model:input="isCurrentPlayerModel" :text="$t('debug.console.playerFilter')" />
      <app-input v-model:input="filter" type="text" :placeholder="$t('debug.filter')" />
      <app-button
        :on-click="onShowLogs"
        variation="button-secondary"
        :text="$t('debug.logs.hide')"
        :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerDebug)"
      />
    </div>
    <div :class="$style.body">
      <div v-for="log in messages" :key="log.date" :class="$style.message" :style="log.css">
        <span>{{ log.date }}: {{ log.levelStr }} - {{ log.message }} - {{ log.args.join(', ') }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { KeyCode } from '@package/sdk/src/core';
import AppButton from '@PLAYER/player/components/ui/AppButton.vue';
import AppCheckbox from '@PLAYER/player/components/ui/AppCheckbox.vue';
import AppInput from '@PLAYER/player/components/ui/AppInput.vue';
import useLogger from '@PLAYER/player/modules/global/use-logger';
import useKeyboardHandler from '@PLAYER/player/modules/hooks/use-keyboard-handler';
import useInstanceId from '@PLAYER/player/modules/instance/use-instance-id';
import { LogMessage } from '@PLAYER/player/modules/logger/default-logger';
import {
  SmartTvPlayerNavigatableGroupName,
  useNavigatableMap,
} from '@PLAYER/player/versions/smart/modules/smart-navigation/use-navigatable-map';
import { computed, markRaw, onBeforeUnmount, ref } from 'vue';

const logger = useLogger();
const keyboard = useKeyboardHandler();
const currentPlayerId = useInstanceId();
const { getNavigationAttribute } = useNavigatableMap();

const emit = defineEmits<{
  (event: 'on-show-logs'): void;
}>();

const onShowLogs = () => {
  emit('on-show-logs');
};

const escapeHandler = keyboard.on(KeyCode.Escape, onShowLogs);

const filter = ref('');
const isCurrentPlayerModel = ref(true);

const logMessages = ref<LogMessage[]>([]);
const MAXIMUM_MESSAGES_SIZE = 1000;

const messages = computed(() => {
  if (!filter.value) {
    return logMessages.value.filter((msg) => msg.playerId === currentPlayerId || !msg.playerId);
  }

  return logMessages.value.filter(
    (msg) => msg.playerId === currentPlayerId && msg.message.toLowerCase().includes(filter.value.toLowerCase()),
  );
});

const onLogSubscribe = (message: LogMessage) => {
  markRaw(message);

  if (logMessages.value.length >= MAXIMUM_MESSAGES_SIZE) {
    logMessages.value.pop();
  }

  logMessages.value.unshift(message);
};

const loggerSubscriber = logger.subscribe(onLogSubscribe);

onBeforeUnmount(() => {
  loggerSubscriber.dispose();
  escapeHandler.dispose();
});
</script>

<style lang="scss" module>
.console {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  margin-right: auto;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}

@supports (position: sticky) {
  .header {
    position: sticky;
    top: -20px;
  }
}

.message {
  background-color: inherit !important;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
</style>
