<template>
  <div
    :class="{
      [$style.timelineNextContent]: true,
      [$style.timelineNextContentSmartTv]: isSmartTV,
    }"
  >
    <app-player-button
      :class="$style.button"
      :on-click="onWatchTitles"
      :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerTimelinePlayback)"
    >
      {{ $t('vod.nextMedia.watchTitles') }}
    </app-player-button>
    <next-episode-preview-element
      :episode-title="episodeTitle"
      :on-request-episode="onRequestEpisode"
      :preview-src="previewSrc"
      :is-button-animated="isButtonAnimated && !isSmartTV"
    />
  </div>
</template>

<script lang="ts" setup>
import { AnyFunction } from '@PLAYER/player/base/function';
import NextEpisodePreviewElement from '@PLAYER/player/components/media-information/NextEpisodePreviewElement.vue';
import AppPlayerButton from '@PLAYER/player/components/ui/AppPlayerButton.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import {
  SmartTvPlayerNavigatableGroupName,
  useNavigatableMap,
} from '@PLAYER/player/versions/smart/modules/smart-navigation/use-navigatable-map';

defineProps<{
  episodeTitle: string;
  previewSrc: string;
  onRequestEpisode: VoidFunction;
  onWatchTitles: AnyFunction;
  isButtonAnimated: boolean;
}>();

const { getNavigationAttribute } = useNavigatableMap();
const { isSmartTV } = usePlatform();
</script>

<style lang="scss" module>
.timelineNextContent {
  display: flex;
}

.timelineNextContentSmartTv {
  .button {
    order: 1;
    margin-left: var(--g-spacing-8);
  }
}

.button {
  order: 0;
  padding: var(--g-spacing-16);
  margin-right: var(--g-spacing-8);
  min-height: 52px;
}
</style>
