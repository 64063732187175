import useSafeAppConfig from '@PLAYER/player/versions/web/modules/config/use-safe-app-config';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const CONFIG_STORE_NAME = 'config';

const useConfigStore = defineStore(CONFIG_STORE_NAME, () => {
  const config = useSafeAppConfig();

  const src = ref(config.src);
  const poster = ref(config.posterSrc);
  const autoplay = ref(config.autoplay);
  const preload = ref(config.preload);
  const muted = ref(config.muted);
  const loop = ref(config.loop);
  const projector = ref(config.projector);

  return {
    projector,
    preload,
    muted,
    loop,
    autoplay,
    poster,
    src,
  };
});

export default useConfigStore;
