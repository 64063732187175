import { ApplicationError } from '@package/sdk/src/core';

export class UnexpectedPropertyConditionError extends ApplicationError {
  public readonly name = 'UnexpectedPropertyConditionError';

  constructor(
    public readonly propertyName: string,
    public readonly currentValue: unknown,
    public readonly expectedValue: string,
  ) {
    super(`Expected ${propertyName} to be "${expectedValue}", get "${String(currentValue)}"`);
  }

  public toJSON(): Record<string, any> {
    const { propertyName, currentValue, expectedValue } = this;

    return {
      propertyName,
      currentValue,
      expectedValue,
    };
  }
}
