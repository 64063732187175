import { isChromium, isFirefox, isMobile } from '@PLAYER/player/base/dom';
import useMediaSeasons from '@PLAYER/player/modules/content/use-media-seasons';
import useExperimentalFeature from '@PLAYER/player/modules/experimental-feature/use-experimental-feature';
import useDVR from '@PLAYER/player/modules/hooks/use-dvr';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

export default function useVideoControlsVariables() {
  const {
    isFullScreenEnabled,
    isFakeFullscreenEnabled,
    isInitialized,
    isRemotePlayAvailable,
    isPlayingOnRemoteDevice,
  } = storeToRefs(useVideoUIStore());

  const { isSeasonsPresent } = useMediaSeasons();
  const { currentAction, isControlsVisible, isMobileBackgroundControlsVisible } = storeToRefs(useVideoControlsStore());
  const { isLive, isKinom, isVOD, isMyChannelPlayer, isContentBackgroundPlayer } = useProjector();
  const { isNextContentViewShown } = useMediaSeasons();
  const { isMediumView } = useRootVideoElBounds();
  const { isDVR } = useDVR();
  const { isSmartTV, isWeb } = usePlatform();
  const { isLiveWithoutDVR } = useVideoPlayerVariables();

  const { isChromecastEnabled } = useExperimentalFeature();

  const videoConfig = useVideoConfig();

  const isLiveDetectorControlShown = computed(() => {
    if (isDVR.value) {
      return true;
    }

    // для смартов не DVR не выводим
    if (!isDVR.value && isSmartTV) {
      return false;
    }

    return isLive.value;
  });

  const isControlsPlaybackShown = computed(() => {
    if (isPlayingOnRemoteDevice.value) {
      return true;
    }

    return isControlsVisible.value;
  });

  const isClosePlayerElementShown = computed(() => {
    if (isKinom.value) {
      return false;
    }

    return isFakeFullscreenEnabled.value || isFullScreenEnabled.value;
  });

  const controlsConfigSettings = computed(() => {
    const fullscreen = videoConfig['video.controls']?.fullscreen;
    const volume = videoConfig['video.controls']?.volume;
    const skipPlayback = videoConfig['video.controls']?.skipPlayback;
    const videoState = videoConfig['video.controls']?.videoState;

    return {
      videoState: videoState ?? true,
      fullscreen: fullscreen ?? true,
      volume: volume ?? true,
      skipPlayback: skipPlayback ?? true,
    };
  });

  const isReplayElementShown = computed(() => isVOD.value);

  const isRemotePlayElementShown = computed(() => {
    if (isKinom.value) {
      return false;
    }

    if (isChromium && !isChromecastEnabled.value) {
      return false;
    }

    return isRemotePlayAvailable.value;
  });

  const isKinomDescriptionShown = computed(() => isKinom.value && videoConfig['video.kinom.isDescriptionShown']);
  const isKinomPlayButtonHidden = computed(() => isKinom.value && videoConfig['video.kinom.isPlayButtonHidden']);

  const isSkipPlaybackControlsShown = computed(() => {
    if (isContentBackgroundPlayer.value) {
      return false;
    }

    if (!controlsConfigSettings.value.skipPlayback) {
      return false;
    }

    if (isLiveWithoutDVR.value) {
      return false;
    }

    return !isMobile;
  });

  const isMobileSkipPlaybackControlsShown = computed(() => {
    if (isMyChannelPlayer.value) {
      return false;
    }

    if (isLiveWithoutDVR.value) {
      return false;
    }

    return true;
  });

  const isMobileBackgroundControlsShown = computed(() => {
    if (!isMobile) {
      return false;
    }

    return isMobileBackgroundControlsVisible.value;
  });

  const isVolumeElementShown = computed(() => {
    if (isContentBackgroundPlayer.value) {
      return false;
    }

    if (!controlsConfigSettings.value.volume) {
      return false;
    }

    if (isKinom.value) {
      return true;
    }

    return true;
  });

  const isVideoStateElementShown = computed(() => {
    if (isMyChannelPlayer.value || isContentBackgroundPlayer.value) {
      return false;
    }

    if (!controlsConfigSettings.value.videoState) {
      return false;
    }

    if (isKinom.value) {
      return true;
    }

    return !isMobile;
  });

  const isSettingsElementShown = computed(() => {
    if (isMobile) {
      if (!isFullScreenEnabled.value) {
        return false;
      }
    }

    return isLive.value || isVOD.value;
  });

  const isFullscreenElementShown = computed(() => {
    if (isContentBackgroundPlayer.value) {
      return false;
    }

    if (!controlsConfigSettings.value.fullscreen) {
      return false;
    }

    if (isFullScreenEnabled.value && isKinomDescriptionShown.value) {
      return true;
    }

    return !isKinomDescriptionShown.value;
  });

  const isEpisodesListControlShown = computed(() => {
    if (!isSeasonsPresent.value) {
      return false;
    }

    if (isMobile) {
      if (!isFullScreenEnabled.value) {
        return false;
      }
    }

    return !isKinom.value;
  });

  const isEpisodesNavigationControlsShown = computed(() => {
    if (!isNextContentViewShown.value) {
      return false;
    }

    return isSeasonsPresent.value;
  });

  const volumeSliderOrientation = computed<'horizontal' | 'vertical'>(() => {
    if (isKinom.value) {
      return 'vertical';
    }

    return isMediumView.value ? 'vertical' : 'horizontal';
  });

  const isPictureInPictureElementShown = computed(() => {
    if (isFirefox) {
      return false;
    }

    return (isVOD.value || isLive.value) && document.pictureInPictureEnabled;
  });

  const isPlaybackRateElementShown = computed(() => {
    // https://forum.webostv.developer.lge.com/t/lg-webos-tv-playback-speed-problem/3150
    // По каким-то причинам, playbackRate отключен в LG/Samsung.
    if (isSmartTV) {
      return false;
    }

    return isVOD.value;
  });

  const isTimelinePlaybackButtonsShown = computed(() => {
    if (!isMobile || isSmartTV) {
      return isVOD.value;
    }

    return isVOD.value && (isFullScreenEnabled.value || isFakeFullscreenEnabled.value);
  });

  const isShownPlaybackTime = computed(() => {
    // Показываем время только для видео с ограниченной длительностью, и не для киномов
    if (isKinom.value || isLive.value) {
      return false;
    }

    return true;
  });

  const currentActionType = computed(() => currentAction.value?.type);

  const isControlsShown = computed(() => {
    if (isWeb) {
      return isInitialized.value;
    }

    return isInitialized.value && (isVOD.value || isLive.value);
  });

  const isRewindShown = computed(() => {
    if (isSmartTV) {
      return false;
    }

    return currentActionType.value === 'skip-backward' || currentActionType.value === 'skip-forward';
  });

  return {
    isLiveDetectorControlShown,
    isPlaybackRateElementShown,
    isControlsPlaybackShown,
    isVideoStateElementShown,
    isVolumeElementShown,
    isReplayElementShown,
    isEpisodesListControlShown,
    isSettingsElementShown,
    isEpisodesNavigationControlsShown,
    isClosePlayerElementShown,
    isKinomDescriptionShown,
    isKinomPlayButtonHidden,
    isFullscreenElementShown,
    isSkipPlaybackControlsShown,
    isMobileSkipPlaybackControlsShown,
    isMobileBackgroundControlsShown,
    isRemotePlayElementShown,
    volumeSliderOrientation,
    isPictureInPictureElementShown,
    isTimelinePlaybackButtonsShown,
    isShownPlaybackTime,
    currentActionType,
    isControlsShown,
    isRewindShown,
  };
}
