<template>
  <div :class="$style.exitModal">
    <h1 :class="$style.exitModalTitle">{{ $t(`${translationPage}.title`) }}</h1>
    <span :class="$style.exitModalHint">{{ $t(`${translationPage}.hint`) }}</span>

    <div :class="$style.exitModalActions">
      <NavigatableItem
        :group="navGroup"
        loop="left,up,down"
        navigation-key="close"
        :class="$style.leftButton"
        :active-class="$style.active"
        :tag="AppButton"
        :text="$t(`${translationPage}.actionClose`)"
        @click="emit('close')"
        @blur="onBlur('close')"
      />
      <NavigatableItem
        :group="navGroup"
        loop="right,up,down"
        navigation-key="exit"
        :active-class="$style.active"
        :tag="AppButton"
        :text="$t(`${translationPage}.actionExit`)"
        @click="emit('exit')"
        @blur="onBlur('exit')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { appNavigationService } from '@package/sdk/src/smarttv';
import { onMounted, onUnmounted, ref } from 'vue';

import AppButton from '../app-button/AppButton.vue';
import NavigatableItem from '../navigation/NavigatableItem.vue';

defineProps<{
  translationPage?: string;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'exit'): void;
}>();

const navGroup = 'exit';

const prevNavigationItem = ref<HTMLElement | undefined>(undefined);

const onBlur = async (key: string) => {
  const group = appNavigationService.activeNavigationGroup;
  if (group.value !== navGroup) {
    const el = await appNavigationService.getNavigationItemByKey(navGroup, key);
    appNavigationService.setActiveNavigationItem(el);
  }
};

onMounted(async () => {
  prevNavigationItem.value = appNavigationService.activeNavigationItem.value;
  appNavigationService.setActiveNavigationItem(await appNavigationService.getNavigationItem(navGroup, 0));
});

onUnmounted(async () => {
  if (prevNavigationItem.value) {
    appNavigationService.setActiveNavigationItem(prevNavigationItem.value as HTMLElement);
  }
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.exitModal {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  &Title {
    @include smartTvFonts.SmartTvTitle-3();
    margin-bottom: adjust.adjustPx(16px);
  }

  &Hint {
    @include smartTvFonts.SmartTvBody-1();
    margin-bottom: adjust.adjustPx(60px);
  }

  &Actions {
    display: flex;
  }
}

.leftButton {
  margin-right: adjust.adjustPx(24px);
}

.active {
  border-color: var(--c-hover-color);
  color: var(--color-text-link);
}
</style>
