<template>
  <div data-smart-tv-control>
    <video-control-button
      :on-click="requestNextEpisode"
      :class="$style.nextEpisode"
      :is-label-shown="true"
      aria-haspopup="true"
      :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerControls)"
      :text="$t('controls.content.next')"
      @focus="openTooltip"
      @blur="onBlur"
    >
      <icon-next-episode size="medium" />
      <span>{{ $t('controls.content.next') }}</span>
    </video-control-button>
    <smart-tv-next-episode-controls-preview
      v-if="isNextEpisodePreviewShown"
      :episode="nextAvailableEpisode.episode"
      :season="currentSelectedSeason"
      :on-request-episode="() => {}"
      @active="onActiveTooltip"
    />
  </div>
</template>

<script lang="ts" setup>
import VideoControlButton from '@PLAYER/player/components/controls/VideoControlButton.vue';
import IconNextEpisode from '@PLAYER/player/components/icons/IconNextEpisode.vue';
import useNextSerialEpisode from '@PLAYER/player/modules/content/use-next-serial-episode';
import useEpisodesNavigationActions from '@PLAYER/player/modules/hooks/use-episodes-navigation-actions';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useTooltipState from '@PLAYER/player/modules/timeline/use-tooltip-state';
import SmartTvNextEpisodeControlsPreview from '@PLAYER/player/versions/smart/components/ui/SmartTvNextEpisodeControlsPreview.vue';
import {
  SmartTvPlayerNavigatableGroupName,
  useNavigatableMap,
} from '@PLAYER/player/versions/smart/modules/smart-navigation/use-navigatable-map';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, ref } from 'vue';

const { getNavigationAttribute } = useNavigatableMap();
const { isTooltipShown, openTooltip, closeTooltip } = useTooltipState();
const { requestNextAvailableEpisode, nextAvailableEpisode } = useNextSerialEpisode();
const { isLinkedKinomShown } = storeToRefs(useLayoutStore());
const { timelineBarHandleEl } = storeToRefs(useVideoControlsStore());
const { currentSelectedSeason } = useEpisodesNavigationActions();
const { isSmartTV } = usePlatform();

const isTooltipSelected = ref(false);

const isHideForced = ref(false);

let isHideForcedTimeoutId: number;

const isNextEpisodePreviewShown = computed(
  () => isTooltipShown.value && nextAvailableEpisode.value && !isLinkedKinomShown.value && !isHideForced.value,
);

const requestNextEpisode = () => {
  isHideForced.value = true;

  isHideForcedTimeoutId = window.setTimeout(() => {
    isHideForced.value = false;
  }, 2000);

  requestNextAvailableEpisode();

  if (timelineBarHandleEl.value) {
    window.$setActiveNavigationItem(timelineBarHandleEl.value);
  }
};

const onBlur = () => {
  if (!isSmartTV) {
    return closeTooltip();
  }

  window.setTimeout(() => {
    if (!isTooltipSelected.value) {
      closeTooltip();
    }
    isTooltipSelected.value = false;
  }, 200);
};

const onActiveTooltip = () => {
  isTooltipSelected.value = true;
};
onBeforeUnmount(() => {
  if (isHideForcedTimeoutId) {
    window.clearTimeout(isHideForcedTimeoutId);
  }
});
</script>

<style lang="scss" module>
.nextEpisode {
  display: flex;
  align-items: center;
}
</style>
