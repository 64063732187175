import { UnexpectedPropertyConditionError } from '@package/media-player/src/player/errors/unexpected-property-condition-error';
import { clone, Disposable, EventEmitter, UnexpectedComponentStateError } from '@package/sdk/src/core';
import type { AnyFunction } from '@package/sdk/src/core/structures/common';
import { logger } from '@package/sdk/src/smarttv/services';
import type { DeviceService } from '@package/sdk/src/smarttv/services/device/device-service';
import { Endpoints } from '@package/sdk/src/smarttv/services/endpoints';
import type { EnvironmentService } from '@package/sdk/src/smarttv/services/environment/environment-service';
import type { AlertService } from '@package/sdk/src/smarttv/services/notifications/alert-service';
import { AlertMessageTypes } from '@package/sdk/src/smarttv/services/notifications/alert-service';
import type { RequestService } from '@package/sdk/src/smarttv/services/request-service';
import type { IStorageService } from '@package/sdk/src/smarttv/services/storage/storage-service';
import { isDefined } from '@vueuse/core';
import { Centrifuge, ConnectedContext, DisconnectedContext, ErrorContext } from 'centrifuge';
import * as vectorclock from 'vectorclock';

import AppEvent from '@/sdk/base/app-event';
import { CancellablePromise } from '@/sdk/base/cancellable-promise';
import type {
  RCEvent,
  RCEventPayloadMap,
  RemoteContentStartContentEvent,
  RemoteControlPairedEvent,
  RemoteControlPlaylistItem,
  RemoteDeviceInfo,
} from '@/services/remote-control/rc-event';
import { PlaybackState, RCEventTopic } from '@/services/remote-control/rc-event';

export class RemoteControlEvent<T> extends AppEvent<T> {
  constructor(data: T) {
    super(data);
  }
}

interface RemoteControlSessionConstructorOptions {
  immediateConnection: boolean;
  uid: string;
}

export interface UpdateTvStateOptions {
  useApi?: boolean;
  volume: number;
  state: PlaybackState;
  content: RemoteControlPlaylistItem | undefined;
  offset: number;
  playlist?: RemoteControlPlaylistItem[];
}

interface CurrentTvState {
  vclock: Record<string, number>;
  state: PlaybackState;
  volume_pct: number;
  content?: {
    id: string;
    offset: number;
    parent_content_id?: string;
    quality?: {
      active: boolean;
      levels: number[];
    };
    playlist?: RemoteControlPlaylistItem[];
  };
}

type EmitterRCEventPayloadMap = {
  [Property in keyof RCEventPayloadMap]: RemoteControlEvent<RCEventPayloadMap[Property]>;
};

const SEND_TV_STATE_UPDATE_MS_DEFAULT_TIMEOUT = 300000;
const SEND_TV_STATE_UPDATE_MS_ERROR_TIMEOUT = 30000;

export class RemoteControlSession extends Disposable {
  public readonly tech: Centrifuge;
  private readonly emitter = new EventEmitter<EmitterRCEventPayloadMap>();

  private currentPlaybackState: PlaybackState = PlaybackState.Stopped;
  private currentVolumeLevel = 0;
  private currentPlaybackOffset = 0;

  private currentPlaylistItem?: RemoteControlPlaylistItem;
  private currentPlaylist?: RemoteControlPlaylistItem[];

  private vclock: Record<string, number> = { rc: 0, tv: 0 };
  private sendTvStateTimeout?: number;
  private tickSendTvStateTimeout?: number;
  private hasTvStateUpdateError = false;

  private cancellablePromiseTvStateUpdate?: CancellablePromise<void>;

  constructor(
    private readonly storageService: IStorageService,
    private readonly deviceService: DeviceService,
    private readonly alertService: AlertService,
    private readonly requestService: RequestService,
    private readonly environmentService: EnvironmentService,
    private readonly options: RemoteControlSessionConstructorOptions,
  ) {
    super();

    const endpoint = this.environmentService.getVariable('apiRCSocketURL') as string;

    logger.info('RemoteControlSession#constructor', endpoint);

    if (!endpoint) {
      throw new UnexpectedComponentStateError('apiRCSocketURL');
    }

    try {
      this.tech = new Centrifuge(endpoint, {
        getToken: async () => {
          const getCentrifugoToken = async () => {
            const { data } = await this.requestService.request<{ token: string }>(
              {
                url: Endpoints.RcTokenGenerate,
                method: 'POST',
                data: {
                  device: this.device,
                },
              },
              { withSignature: false, withToken: true },
            );

            return data.token;
          };

          try {
            const token = await getCentrifugoToken();

            return token;
          } catch (err) {
            await requestService.updateTokens();

            const token = await getCentrifugoToken();

            return token;
          }
        },
      });

      if (options.immediateConnection) {
        this.tech.connect();
      }

      this.tech.on('connected', this.onConnected);
      this.tech.on('disconnected', this.onDisconnected);
      this.tech.on('publication', this.onMessageReceived);
      this.tech.on('error', this.onError);
    } catch (error) {
      logger.error('RemoteControlSession#error', error);
    }
  }

  public get device(): RemoteDeviceInfo {
    const device = this.deviceService.getDevice();

    return {
      id: this.options.uid,
      name: device.name as string,
      type: 'tv',
      volume_pct: this.currentVolumeLevel,
      flags: {
        streaming_quality_change: false,
        volume_change: false,
      },
    };
  }

  public updateTvState(options: UpdateTvStateOptions) {
    const isSendToApi = isDefined(options.useApi) ? options.useApi : true;

    this.currentPlaybackState = options.state;
    this.currentVolumeLevel = options.volume;
    this.currentPlaybackOffset = options.offset;
    this.currentPlaylistItem = options.content;
    this.currentPlaylist = options.playlist;

    if (this.currentPlaylistItem && this.currentPlaybackState === PlaybackState.Stopped) {
      return console.error(
        new UnexpectedPropertyConditionError('currentPlayBackState', this.currentPlaybackState, 'paused | playing'),
      );
    }

    if (!this.currentPlaylistItem && this.currentPlaybackState !== PlaybackState.Stopped) {
      return console.error(
        new UnexpectedPropertyConditionError('currentPlayBackState', this.currentPlaybackState, 'stopped'),
      );
    }

    if (isSendToApi) {
      this.createCancellablePromiseTvUpdate();
    }
  }

  public disconnect() {
    this.tech.disconnect();
  }

  private get currentTvState(): CurrentTvState {
    const { vclock, currentPlaybackState, currentVolumeLevel, currentPlaybackOffset } = this;

    const state: CurrentTvState = {
      vclock,
      state: currentPlaybackState,
      volume_pct: currentVolumeLevel,
    };

    if (this.currentPlaylistItem) {
      const { content_id, parent_content_id, type } = this.currentPlaylistItem;

      const content: CurrentTvState['content'] = {
        id: content_id,
        offset: Math.floor(currentPlaybackOffset),
      };

      if (type === 'series') {
        Reflect.set(content, 'parent_content_id', parent_content_id);
      }

      content.playlist = this.currentPlaylist;

      Reflect.set(state, 'content', content);
    }

    return state;
  }

  private createCancellablePromiseTvUpdate() {
    try {
      this.cancellablePromiseTvStateUpdate?.cancel();

      this.cancellablePromiseTvStateUpdate = new CancellablePromise((resolve, _) => {
        this.sendTvState()
          .then(() => resolve())
          .catch(() => {
            // it's ok
          });
      });

      this.cancellablePromiseTvStateUpdate.catch(() => {
        // it's ok
      });
    } catch (error) {
      // it's ok
    }
  }

  private sendTvState() {
    return new Promise((resolve, reject) => {
      const data = this.currentTvState;
      data.vclock = this.incrementVectorClock();

      this.requestService
        .request(
          {
            method: 'PUT',
            url: Endpoints.RcTvSessionState.replace(':id', this.options.uid),
            data: {
              data,
            },
          },
          { withToken: true, transformResult: true, canAbort: true },
        )
        .catch((e) => {
          reject(e);
          this.hasTvStateUpdateError = true;
        })
        .then(() => {
          this.vclock = data.vclock;
          this.hasTvStateUpdateError = false;
          resolve(undefined);
        });
    });
  }

  private onError = (error: ErrorContext) => {
    logger.error('RemoteControlSession#onError', error);
  };

  private onConnected = (_: ConnectedContext) => {
    this.tickSendTvState();
  };

  private onDisconnected = (_: DisconnectedContext) => {
    if (this.sendTvStateTimeout) {
      window.clearTimeout(this.sendTvStateTimeout);
    }

    if (this.tickSendTvStateTimeout) {
      window.clearTimeout(this.tickSendTvStateTimeout);
    }
  };

  private onMessageReceived = (ctx: RCEvent) => {
    const vclock = ctx.data.payload.vclock;

    if (!vclock) {
      return this.processCallWithoutVClock(ctx);
    }

    return this.processCallWithVClock(ctx);
  };

  private processCallWithoutVClock(ctx: RCEvent) {
    const topic = ctx.data.topic;

    const callbacksWithoutVClock: Record<string, AnyFunction> = {
      [RCEventTopic.Paired]: (event: RemoteControlEvent<RemoteControlPairedEvent>) =>
        this.emitter.emit(RCEventTopic.Paired, event),
      [RCEventTopic.Unpair]: (event: RemoteControlEvent<undefined>) => {
        this.emitter.emit(RCEventTopic.Unpair, event);
      },
      [RCEventTopic.PairChanged]: () => {},
    };

    this.processEvent(topic, callbacksWithoutVClock[topic], ctx.data.payload);
  }

  private processCallWithVClock(ctx: RCEvent) {
    const topic = ctx.data.topic;

    const currentVersion = clone(this.vclock);

    const isRelease = this.environmentService.getVariable<boolean>('isRelease');

    const callbacksWithClock: Record<string, AnyFunction> = {
      [RCEventTopic.Play]: (event) => this.emitter.emit(RCEventTopic.Play, event),
      [RCEventTopic.Pause]: (event) => this.emitter.emit(RCEventTopic.Pause, event),
      [RCEventTopic.Stop]: (event) => this.emitter.emit(RCEventTopic.Stop, event),
      [RCEventTopic.StartContent]: (event: RemoteControlEvent<RemoteContentStartContentEvent>) =>
        this.emitter.emit(RCEventTopic.StartContent, event),
      [RCEventTopic.OffsetChange]: (event) => this.emitter.emit(RCEventTopic.OffsetChange, event),
      [RCEventTopic.VolumeChange]: (event) => this.emitter.emit(RCEventTopic.VolumeChange, event),
      [RCEventTopic.QualityChange]: (event) => this.emitter.emit(RCEventTopic.QualityChange, event),
    };

    const isIgnoreVclockLogic = this.storageService.getItem<boolean>('ignore_vclock');

    const isConcurrentVersion = vectorclock.isConcurrent(ctx.data.payload.vclock, currentVersion);
    const isNewVersion = vectorclock.compare(ctx.data.payload.vclock, currentVersion) === vectorclock.GT;

    if (!isIgnoreVclockLogic) {
      let text = '';

      if (!isNewVersion) {
        text = `${ctx.data.topic}, vclock на ТВ новее`;
      } else if (isConcurrentVersion) {
        text = `${ctx.data.topic}, vclock одинаковые`;
      }

      if (text) {
        if (!isRelease) {
          this.alertService.addAlert({
            type: AlertMessageTypes.Warning,
            message: text,
          });
        }

        this.createCancellablePromiseTvUpdate();
      }
    }

    vectorclock.increment(currentVersion, 'tv');
    this.vclock = vectorclock.merge(currentVersion, ctx.data.payload.vclock);

    if ((!isConcurrentVersion && isNewVersion) || isIgnoreVclockLogic) {
      this.processEvent(topic, callbacksWithClock[topic], ctx.data.payload);
    }
  }

  private incrementVectorClock() {
    const currentVersion = clone(this.vclock);
    return vectorclock.increment(currentVersion, 'tv');
  }

  private tickSendTvState() {
    if (this.tickSendTvStateTimeout) {
      window.clearTimeout(this.tickSendTvStateTimeout);
    }

    const timeoutMs = !this.hasTvStateUpdateError
      ? SEND_TV_STATE_UPDATE_MS_DEFAULT_TIMEOUT
      : SEND_TV_STATE_UPDATE_MS_ERROR_TIMEOUT;

    this.tickSendTvStateTimeout = window.setTimeout(() => {
      this.createCancellablePromiseTvUpdate();
      this.tickSendTvState();
    }, timeoutMs);
  }

  private processEvent(topic: RCEventTopic, handler: AnyFunction, payload: unknown) {
    if (!handler) {
      return console.error(`Expect handler for topic ${topic}`);
    }

    const event = new RemoteControlEvent(payload);

    Reflect.apply(handler, undefined, [event]);
  }

  public addEventListener<T extends keyof EmitterRCEventPayloadMap>(
    event: T,
    listener: (arg: EmitterRCEventPayloadMap[T]) => void,
  ) {
    return this.emitter.on(event, listener);
  }

  public removeEventListener<T extends keyof EmitterRCEventPayloadMap>(
    event: T,
    listener: (arg: EmitterRCEventPayloadMap[T]) => void,
  ) {
    return this.emitter.removeEventListener(event, listener);
  }

  public dispose() {
    this.emitter.dispose();
    this.currentPlaybackState = PlaybackState.Stopped;
    this.currentPlaylistItem = undefined;
    this.currentPlaybackOffset = 0;
    this.currentVolumeLevel = 0;

    if (this.sendTvStateTimeout) {
      window.clearTimeout(this.sendTvStateTimeout);
    }

    super.dispose();
  }
}
