import { ensurePromiseExist, isMobile } from '@PLAYER/player/base/dom';
import logger from '@PLAYER/player/modules/logger/default-logger';

const exitFullScreen = async () => {
  try {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }

    if (!isMobile) {
      return;
    }

    const promise = ensurePromiseExist(window.screen.orientation.lock('portrait'));

    if (promise) {
      await promise;
    }
  } catch (error) {
    logger.error(error);
  }
};

export default function useFullscreenActions() {
  return {
    exitFullScreen,
  };
}
