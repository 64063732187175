import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

export default function useProjector() {
  const videoConfig = useVideoConfig();

  const isMyChannelPlayer = computed(() => videoConfig['video.projector'] === 'my-channel');
  const isContentBackgroundPlayer = computed(() => videoConfig['video.projector'] === 'content-background');

  const isKinom = computed(
    () => videoConfig['video.projector'] === 'kinom' || isMyChannelPlayer.value || isContentBackgroundPlayer.value,
  );
  const isLive = computed(() => videoConfig['video.projector'] === 'live');
  const isVOD = computed(() => videoConfig['video.projector'] === 'vod');

  return {
    isKinom,
    isLive,
    isVOD,
    isContentBackgroundPlayer,
    isMyChannelPlayer,
  };
}
