import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import type { Ref } from 'vue';
import { inject } from 'vue';

export default function useSafeLabelElement() {
  const labelEl = inject<Ref<HTMLElement>>('labelEl');

  if (!labelEl) {
    throw new UnexpectedComponentStateError('labelEl');
  }

  return labelEl;
}
