import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { inject, Ref } from 'vue';

export default function useSafeCurrentVideoSessionCounter() {
  const currentVideoSessionCounter = inject<Ref<number>>('app.video.session.counter');

  if (!currentVideoSessionCounter) {
    throw new UnexpectedComponentStateError('currentVideoSessionCounter');
  }

  return currentVideoSessionCounter;
}
