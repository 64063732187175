export function getChromeVersion() {
  try {
    const agents = navigator.userAgent.split(' ');

    for (let i = 0; i < agents.length; i++) {
      if (agents[i].substring(0, 5) === 'Chrom') {
        return Number(agents[i].split('/')[1].split('.')[0]);
      }
    }

    return 0;
  } catch (error) {
    return 0;
  }
}

export function getTizenVersion() {
  try {
    const agents = navigator.userAgent.split(' ');

    for (let i = 0; i < agents.length; i++) {
      if (agents[i].substring(0, 5) === 'Tizen') {
        return parseFloat(agents[i + 1]);
      }
    }

    return 0;
  } catch (error) {
    return 0;
  }
}
