export enum StorageKeys {
  Onboarding = 'onboarding',
  User = 'user',
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
  Quiz = 'quiz',
  Profile = 'profile',
  Visitor = 'VisitorId',
  DeviceUUID = 'device_uuid',
  Telemetry = 'Telemetry',
  isProdApiEnabledDebug = 'debug.is_prod_api_enabled',
}
