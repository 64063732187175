import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import VideoEventHandler from '@PLAYER/player/modules/video/video-event-handler';
import { inject } from 'vue';

export default function useSafeVideoEventHandler() {
  const eventHandler = inject<VideoEventHandler>('videoEventHandler');

  if (!eventHandler) {
    throw new UnexpectedComponentStateError('videoEventHandler');
  }

  return eventHandler;
}
