import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { MediaElementDOMInstance } from '@PLAYER/player/modules/video/media-element-dom-instance';
import { inject, Ref } from 'vue';

export default function useSafeVideoElement() {
  const videoEl = inject<Ref<HTMLVideoElement>>('videoEl');

  if (!videoEl) {
    throw new UnexpectedComponentStateError('videoEl');
  }

  return new MediaElementDOMInstance(videoEl.value);
}
