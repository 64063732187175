<template>
  <div data-smart-tv-control>
    <video-control-button
      :on-click="onClick"
      :class="$style.episodesList"
      :is-label-shown="true"
      aria-haspopup="true"
      :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerControls)"
      :text="$t('controls.content.episodes')"
    >
      <icon-episodes />
      <span>{{ $t('controls.content.episodes') }}</span>
    </video-control-button>
  </div>
</template>

<script lang="ts" setup>
import VideoControlButton from '@PLAYER/player/components/controls/VideoControlButton.vue';
import IconEpisodes from '@PLAYER/player/components/icons/IconEpisodes.vue';
import {
  CloseAllControlElements,
  RedirectRequiredEvent,
  VideoPlayerExternalEvent,
} from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import {
  SmartTvPlayerNavigatableGroupName,
  useNavigatableMap,
} from '@PLAYER/player/versions/smart/modules/smart-navigation/use-navigatable-map';

const { getNavigationAttribute } = useNavigatableMap();

const externalEventBus = useSafeExternalEventBus();

const onClick = () => {
  externalEventBus.emit(
    'close-all-control-elements',
    new VideoPlayerExternalEvent<CloseAllControlElements>({
      shouldBeIgnored: true,
    }),
  );
  externalEventBus.emit(
    'redirect-required',
    new VideoPlayerExternalEvent<RedirectRequiredEvent>({
      page: 'episodes-menu',
    }),
  );
};
</script>

<style lang="scss" module>
.episodesList {
  display: flex;
  align-items: center;
}
</style>
