import { TvKeyCode } from '../../core/keyboard/tv-keys';
import { keyboardEventHandler } from '../navigation/keyboard-event-handler';

type Directive = any;
type HandlerFn = (flag: boolean) => void;

const LONG_PRESS_TIME = 500;
let isKeyPress = false;
let startDate: Date;
let endDate: Date;
let handler: HandlerFn | null = null;

const onKeyDown = (event: KeyboardEvent) => {
  const eventCode = keyboardEventHandler.getTvKeyCode(event.keyCode);

  if (eventCode !== TvKeyCode.ENTER || isKeyPress || !handler) {
    return;
  }

  isKeyPress = true;
  startDate = new Date();
};

const onKeyUp = () => {
  if (!isKeyPress || !handler) {
    return;
  }

  endDate = new Date();
  isKeyPress = false;

  if (!startDate) {
    return;
  }

  const diff = Math.abs(startDate.getTime() - endDate.getTime());

  handler(diff > LONG_PRESS_TIME);
};

export const longPressDirective: Directive = {
  mounted(el: HTMLElement, binding: any) {
    handler = binding.value;

    el.addEventListener('keydown', onKeyDown);
    el.addEventListener('keyup', onKeyUp);
  },
  updated(_: HTMLElement, binding: any) {
    handler = binding.value;
  },
  unmounted(el: HTMLElement) {
    handler = null;

    el.removeEventListener('keydown', onKeyDown);
    el.removeEventListener('keyup', onKeyUp);
  },
};
