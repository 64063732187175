import { isDefined, isTrue } from '@package/sdk/src/core';
import { isIOS, isTizenOS, isWebOS, isWebOSSimulator } from '@PLAYER/player/base/dom';
import { UnexpectedPropertyConditionError } from '@PLAYER/player/errors/unexpected-property-condition-error';
import { M3U8Parser } from '@PLAYER/player/modules/hls/m3u8-parser';
import useRealNativeHlsPlaybackSupport from '@PLAYER/player/modules/hooks/use-real-native-playback-support';

export type UseDetectMediaTechSupportResult = 'native' | 'hls' | 'dash' | 'avplayer' | 'tataudio';

interface UseDetectMediaTechSupport {
  forceAVPlayer?: boolean;
  forceTataudio?: boolean;
  forceHlsJs?: boolean;
}

const DEFAULT_OPTIONS = { forceAVPlayer: false };

export default function useDetectMediaTechSupport(
  url?: string,
  options: UseDetectMediaTechSupport = DEFAULT_OPTIONS,
): UseDetectMediaTechSupportResult {
  /**
   * Если ссылка - не m3u8, то это значит что это ТОЧНО не HLS плейлист. Пытаемся проиграть нативно.
   */

  const hasRealSupport = useRealNativeHlsPlaybackSupport();

  const { forceAVPlayer, forceTataudio, forceHlsJs } = options;

  if (isTrue(forceTataudio) && isTrue(forceAVPlayer)) {
    throw new UnexpectedPropertyConditionError('forceTataudio | forceAvPlayer', 'true', 'false');
  }

  // Если это dash (запускаем dash.js)
  if (isDefined(url) && url.includes('.mpd')) {
    return 'dash';
  }

  // форсим hls.js
  if (forceHlsJs) {
    return 'hls';
  }

  // форсим tataudio
  if (forceTataudio) {
    return 'tataudio';
  }

  // форсим avplayer
  if (forceAVPlayer) {
    return 'avplayer';
  }

  // форсим hls.js
  if (isDefined(url) && !M3U8Parser.isM3U8ManifestUrl(url)) {
    return 'native';
  }
  /**
   * Для WebOS нет поддержки нативного HLS, делаем фоллбек на обычный
   */
  if (isWebOSSimulator) {
    return 'hls';
  }

  // Для теликов только если есть реальная поддержка.
  if (hasRealSupport && isTizenOS) {
    return 'native';
  }

  if (hasRealSupport && isWebOS) {
    return 'native';
  }

  if (hasRealSupport && isIOS) {
    return 'native';
  }

  return 'hls';
}
