import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { MediaPlayerPlugin } from '@PLAYER/player/modules/plugin/media-player-plugin';
import { inject } from 'vue';

export default function useSafeMediaPlayerPlugins(): MediaPlayerPlugin[] {
  const plugins = inject('app.plugins');

  if (!plugins) {
    throw new UnexpectedComponentStateError('plugins');
  }

  return plugins;
}
