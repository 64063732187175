<template>
  <AppMenu v-if="!isMenuHidden" :key="menuKey" />
  <AppBuildBadge v-if="isBuildBadgeShown" />
  <RouterView v-slot="{ Component }">
    <KeepAlive
      :key="cacheKey"
      :include="[
        $RouterPage.CatalogPage,
        $RouterPage.SearchPage,
        $RouterPage.ChannelsPage,
        $RouterPage.MediaCardSimilarPage,
        $RouterPage.MyChannelPage,
      ]"
      :max="1"
    >
      <component :is="Component" />
    </KeepAlive>
  </RouterView>

  <UIAlert />

  <FullScreenModal v-if="exitModalShown">
    <AppExitModal translation-page="modals.exit" @close="exitModalResolve(false)" @exit="exitModalResolve(true)" />
  </FullScreenModal>
</template>

<script setup lang="ts">
import { SmartTvVijuPlayer } from '@package/media-player/src/player';
import { isDefined } from '@package/sdk/src/core';
import {
  channelsService,
  contentCacheManager,
  createReconcilationPoint,
  deviceService,
  environmentService,
  initStore,
  logger,
  RouterPage,
  routerService,
  SessionGetters,
  SessionState,
  storeToRefs,
  useContentStore,
  useMainPageStore,
  useSessionStore,
  useTvChannelsStore,
} from '@package/sdk/src/smarttv';
import * as Sentry from '@sentry/vue';
import { nanoid } from 'nanoid';
import { v4 as uuidv4 } from 'uuid';
import { computed, onErrorCaptured, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import AppBuildBadge from '@/components/app-build-badge/AppBuildBadge.vue';
import AppMenu from '@/components/menu/AppMenu.vue';
import useSessionVariables from '@/sdk/session/use-session-variables';

import UIAlert from './components/alert/UIAlert.vue';
import AppExitModal from './components/modal/AppExitModal.vue';
import FullScreenModal from './components/modal/FullScreenModal.vue';

type ResolveFunction = (value: boolean) => void;

const route = useRoute();

const store = initStore();
const contentStore = useContentStore();
const sessionStore = useSessionStore();
const tvChannelsStore = useTvChannelsStore();
const mainPageStore = useMainPageStore();

const { currentOffer, _profile, _user } = storeToRefs<SessionState, SessionGetters, unknown>(sessionStore);
const { isAuth } = useSessionVariables();

const isMenuHidden = ref(false);
const cacheKey = ref(uuidv4());

let routeQuery = '';
let routeParams = '';

const isReleaseMode = environmentService.getVariable<boolean>('isRelease');

// Перед релизом на прод, будем скрывать для прода - оставлять для остальных
const isBuildBadgeShown = computed(() => {
  if (!isReleaseMode) {
    return true;
  }

  return route.path.includes('settings') || route.path.includes('auth');
});

const hiddenAppMenuRoutes = [
  RouterPage.MediaCardPlayerPage,
  RouterPage.ChannelsPlayerPage,
  RouterPage.ProfilesPage,
  RouterPage.Offers,
];

const cachedRoutes = [
  RouterPage.CatalogPage,
  RouterPage.CollectionPage,
  RouterPage.SearchPage,
  RouterPage.ChannelsPage,
  RouterPage.ChannelsPlayerPage,
  RouterPage.Offers,
  RouterPage.OfferInfo,
  RouterPage.MyChannelPage,
  RouterPage.AuthPage,
  RouterPage.MediaCardSimilarPage,
  RouterPage.MediaCardPage,
];

const routesWithoutOptionsCheck = [RouterPage.CatalogPage];

const resetSessionData = () => {
  contentStore.reset();
  tvChannelsStore.reset();
  contentCacheManager.clear();
  mainPageStore.reset();

  channelsService.fetchChannels();
  contentStore.fetchGenres();
  contentStore.fetchPeriods();
  contentStore.fetchCountries();
  sessionStore.fetchOffers();

  cacheKey.value = uuidv4();
};

const onDidProfileUpdated = () => {
  const routeName = route.name;

  /**
   * Сессия может появится при смене пароля, там мы НЕ сбрасываем все, чтобы страница не слетела
   */
  if (routeName === RouterPage.AuthPage) {
    return;
  }

  resetSessionData();

  SmartTvVijuPlayer.setSession({ offer: currentOffer.value, user: _user?.value });
};

const shouldResetCache = (to: RouterPage, from: RouterPage) => {
  return to === RouterPage.CollectionPage && from === RouterPage.MyChannelPage;
};

const onBeforeEach = (to: RouterPage, from: RouterPage) => {
  if (isDefined(to) && hiddenAppMenuRoutes.includes(to)) {
    isMenuHidden.value = true;
    return;
  }

  if (isDefined(to) && isDefined(from) && !cachedRoutes.includes(to)) {
    cacheKey.value = uuidv4();
  }

  if (shouldResetCache(to, from)) {
    cacheKey.value = uuidv4();
  }

  isMenuHidden.value = false;
};

const menuKey = computed(() => {
  return _user?.value?.id ?? nanoid(3);
});

watch(() => route.name, onBeforeEach);
watch(
  () => route.params,
  (params) => {
    const paramsString = JSON.stringify(params);
    if (
      routeParams !== paramsString &&
      !routesWithoutOptionsCheck.includes(routerService.lastVisitedRoute?.name as RouterPage)
    ) {
      routeParams = paramsString;
      cacheKey.value = uuidv4();
    }
  },
);
watch(
  () => route.query,
  (query) => {
    const queryString = JSON.stringify(query);
    if (routeQuery !== queryString) {
      routeQuery = queryString;
      cacheKey.value = uuidv4();
    }
  },
);
watch(() => _profile?.value, onDidProfileUpdated, { immediate: true });

const exitModalShown = ref(false);
const exitModalResolve = ref<ResolveFunction>(() => {});

const showAppExitModal = async (): Promise<boolean> => {
  exitModalShown.value = true;

  return new Promise((res) => {
    exitModalResolve.value = (value: boolean) => {
      exitModalShown.value = false;

      if (value) {
        deviceService.exit();
      }

      res(value);
    };
  });
};

window.$exports = { showAppExitModal };

// Скорей всего, если мы оказались тут - то у нас крашнулось приложение
onErrorCaptured((error) => {
  logger.error(error?.toString());
  logger.error(error?.stack as string);

  Sentry.captureException(error);

  throw error;
});

onMounted(createReconcilationPoint);
</script>
