import type * as Sentry from '@sentry/vue';
import type { Vue } from '@sentry/vue/types/types';

import { UnexpectedComponentStateError } from '../../../core';
import { environmentService } from '../index';

enum SentryEnvironment {
  Development = 'development',
  Production = 'production',
  Stage = 'stage',
  Unknown = 'unknown',
}

function getEnvironmentName(): SentryEnvironment {
  switch (true) {
    // Мы на production.
    case environmentService.getVariable('isRelease'):
      return SentryEnvironment.Production;
    // мы в режиме разработки
    case environmentService.getVariable('isDev'):
      return SentryEnvironment.Development;
    // мы на тестовом стенде (testvip)
    case !environmentService.getVariable('isDev'):
      return SentryEnvironment.Stage;
  }

  throw new UnexpectedComponentStateError('sentryEnvironment');
}

export function setupSentry(app: Vue | unknown, router: any, sentry: typeof Sentry) {
  const environmentName = getEnvironmentName();

  if (environmentName === SentryEnvironment.Unknown || environmentName === SentryEnvironment.Development) {
    return;
  }

  sentry.init({
    app: app as Vue,
    attachProps: true,
    logErrors: true,
    trackComponents: true,
    environment: environmentName,
    release: environmentService.getVariable<() => string>('appVersion')(),
    dsn: 'https://ef4ba21a2624456b9ed87b7aee4b05fc@sentry.viju.ru/4',
    integrations: [sentry.browserTracingIntegration({ router, routeLabel: 'name' })],
    tracesSampleRate: 1.0,
    beforeSend(event: Sentry.Event, hint?: Sentry.EventHint) {
      const originalException = hint?.originalException;

      if (!originalException) {
        return event;
      }

      const { message } = originalException as Error;

      // Такое иногда вылетает у плеера, не отправляем в Sentry
      if (message.includes('webkitExitFullScreen')) {
        return null;
      }

      // Не отправляем в Sentry ошбики при отмене запроса к API
      if (message.includes('canceled')) {
        return null;
      }

      return event;
    },
  });
}
