<template>
  <template v-if="projector === 'live'">
    <projector-video-live />
  </template>
  <template v-else-if="projector === 'kinom'">
    <projector-video-kinom>
      <kinom-not-loaded-preview-layer v-if="isKinomPreviewShown" />
      <current-season-detector />
      <kinom-media-next-item-controller />
    </projector-video-kinom>
  </template>
  <template v-else-if="projector === 'vod'">
    <projector-video-vod>
      <current-season-detector />
      <slot name="vod" />
    </projector-video-vod>
  </template>
</template>

<script lang="ts" setup>
import { isSafari } from '@PLAYER/player/base/dom';
import CurrentSeasonDetector from '@PLAYER/player/components/media-information/CurrentSeasonDetector.vue';
import KinomMediaNextItemController from '@PLAYER/player/components/media-information/KinomMediaNextItemController.vue';
import KinomNotLoadedPreviewLayer from '@PLAYER/player/components/media-information/KinomNotLoadedPreviewLayer.vue';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import useExperimentalFeature from '@PLAYER/player/modules/experimental-feature/use-experimental-feature';
import type { PlayerProjector } from '@PLAYER/player/modules/instance/interfaces';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import ProjectorVideoKinom from './ProjectorVideoKinom.vue';
import ProjectorVideoLive from './ProjectorVideoLive.vue';
import ProjectorVideoVod from './ProjectorVideoVod.vue';

defineProps<{
  projector: PlayerProjector;
  src?: string;
  preload?: boolean;
  posterSrc?: string;
  autoplay?: boolean;
  loop?: boolean;
  muted?: boolean;
}>();

const { isLazyLoadingInteractionEnabled } = useExperimentalFeature();
const { isInitialized } = storeToRefs(useVideoUIStore());
const { content } = useContentInformation();

const isVideoPreviewShownInner = ref(true);

watch(content, () => {
  if (isSafari) {
    isVideoPreviewShownInner.value = true;
  }
});

watch(isInitialized, () => {
  if (isInitialized.value) {
    isVideoPreviewShownInner.value = false;
  }
});

const isKinomPreviewShown = computed(() => isVideoPreviewShownInner.value && isLazyLoadingInteractionEnabled.value);
</script>
