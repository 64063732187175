<template>
  <root-video-z-index-layer :class="$style.debug" fullscreen>
    <component :is="component" @on-show-logs="onShowLogs" @on-show-settings="onShowSettings" />
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import DebugInformationConsole from '@PLAYER/player/components/debug/DebugInformationConsole.vue';
import DebugInformationMetadata from '@PLAYER/player/components/debug/DebugInformationMetadata.vue';
import DebugInformationSettings from '@PLAYER/player/components/debug/DebugInformationSettings.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import useDebugActions from '@PLAYER/player/modules/debug/useDebugActions';
import { computed, onMounted, ref } from 'vue';

const isConsoleShown = ref(false);
const isSettingsShown = ref(false);

const { releaseDebugMenu } = useDebugActions();

const onShowLogs = () => {
  isSettingsShown.value = false;
  isConsoleShown.value = !isConsoleShown.value;
};

const onShowSettings = () => {
  isConsoleShown.value = false;
  isSettingsShown.value = !isSettingsShown.value;
};

const component = computed(() => {
  if (isConsoleShown.value) {
    return DebugInformationConsole;
  }

  if (isSettingsShown.value) {
    return DebugInformationSettings;
  }

  return DebugInformationMetadata;
});

onMounted(() => {
  window.$setOnPressBackCallback(releaseDebugMenu, true);
});
</script>

<style lang="scss" module>
@import '@PLAYER/player/styles/custom-scroll-mixin';

:global(.mobile) .debug {
  width: 100%;
}

.debug {
  --debug-background-color: rgba(0, 0, 0, 0.9);

  @include customScrollBarStyles();
  z-index: var(--z-index-debug-view);
  padding: 20px;
  overflow-y: scroll;
  background-color: var(--debug-background-color);
}
</style>
