import { globalSettings } from '../services';
import logger from '../services/logger/logger';

type JwtPayload = any;

export type AuthTokenPayload = JwtPayload & {
  user_id: string;
  profile_id: string;
  visitor_id: string;
};

export function useJwtDecoder() {
  const decode = (token: string): AuthTokenPayload | undefined => {
    try {
      return globalSettings.jwt(token);
    } catch (err) {
      logger.error('Unexpected situation: Bad JWT token.');
    }
  };

  return { decode };
}
