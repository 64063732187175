import { Country } from '@package/sdk/src/api';

import { Channel } from '../../api/channels/types/channel';
import { Media } from '../../api/content/types/media';
import { Moment } from '../../api/content/types/moment';
import { Movie } from '../../api/content/types/movie';
import { Serial } from '../../api/content/types/serial';
import { catalogService } from '../services';
import { defineStore } from './utils/define-store';
import { getStoreProperty, VuexStoreNamespace } from './utils/namespaces';

type SelectedItemValue = Movie | Serial | Media | Moment | Channel | null;
type SelectedItem = { item: SelectedItemValue; rowId: number };

export interface CatalogState {
  _selectedItem: SelectedItem | null;
  _isUpdated: boolean;
  _countries: Country[];
}

export interface CatalogActionWithoutStoreArgument {
  updateSelectedItem(props: { value?: SelectedItemValue; rowId?: number } | null): void;

  setUpdated(value: boolean): void;

  fetchCountries(): Promise<void>;
}

export interface CatalogGetters {
  readonly selectedItem: SelectedItem;
  readonly isUpdated: boolean;
  readonly countries: Country[];
}

export const useCatalogStore = defineStore<
  CatalogState,
  CatalogGetters,
  CatalogActionWithoutStoreArgument,
  VuexStoreNamespace.Catalog
>(VuexStoreNamespace.Catalog, {
  state: (): CatalogState => ({
    _selectedItem: null,
    _isUpdated: false,
    _countries: [],
  }),
  getters: {
    selectedItem: (state) => state._selectedItem,
    isUpdated: (state) => state._isUpdated,
    countries: (state) => state._countries,
  },
  actions: {
    updateSelectedItem(
      store,
      props: {
        value?: SelectedItemValue;
        rowId?: number;
      } | null,
    ): void {
      const item = props || {};

      if (!item?.value) {
        store.commit(getStoreProperty(VuexStoreNamespace.Catalog, '$patch'), { _selectedItem: null });
        return;
      }

      store.commit(getStoreProperty(VuexStoreNamespace.Catalog, '$patch'), {
        _selectedItem: item,
      });
    },
    setUpdated(store, value: boolean) {
      store.commit(getStoreProperty(VuexStoreNamespace.Catalog, '$patch'), { _isUpdated: value });
    },
    async fetchCountries(store) {
      if (store.state[VuexStoreNamespace.Catalog]._countries?.length) {
        return;
      }

      store.commit(getStoreProperty(VuexStoreNamespace.Catalog, '$patch'), {
        _countries: await catalogService.fetchCountries(),
      });
    },
  },
});
