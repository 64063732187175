import { TimeSeconds } from '@PLAYER/player/base/number';

export type PlayerProjector = 'kinom' | 'vod' | 'live' | 'playlist' | 'my-channel' | 'content-background';

export enum PlayerGlobalProperty {
  StartMediaSession = '$startMediaSession',
  EndMediaSession = '$endMediaSession',

  Touch = '$touch',

  VideoEl = '$videoEl',
  RequestKinomEditorMode = '$requestKinomEditorMode',
  RequestDebugMenu = '$requestDebugMenu',

  SetQualityLevel = '$setQualityLevel',
  Play = '$play',
  Pause = '$pause',
  StopLoad = '$stopLoad',
  StartLoad = '$startLoad',
  SeekTo = '$seekTo',
  GetFullscreenState = '$getFullscreenState',
  RequestFullscreen = '$requestFullscreen',
  ExitFullscreen = '$exitFullscreen',
  RequestFakeFullscreen = '$requestFakeFullscreen',
  CloseFakeFullscreen = '$closeFakeFullscreen',
  GetSourceLink = '$getSourceLink',
  LoadSource = '$loadSource',

  GetPictureInPictureState = '$getPictureInPictureState',
  OpenPictureInPicture = '$openPictureInPicture',
  ClosePictureInPicture = '$closePictureInPicture',

  PlayerMounted = '$mounted',
  MediaTechCreated = '$mediaTechCreated',
}

export interface PlayerInstanceConstructorOptions {
  readonly projector: PlayerProjector;
  readonly src?: string;
  readonly autoplay?: boolean;
  readonly loop?: boolean;
  readonly posterSrc?: string;
  readonly muted?: boolean; // Громкость - ноль, и пользователь не сможет ее изменить.

  /**
   * @description
   *
   * Только для html5 плеера. Нужно ли загружать видео сразу.
   */
  readonly preload?: boolean;
  /**
   * @description
   *
   * Только для hls.js плеера. Нужно ли грузить видео сразу при загрузке манифеста.
   *
   * @see {VijuPlayer.startLoad}
   */
  readonly autoStartLoad?: boolean;
}

export interface PlayerInstanceAbstractCommandOptions {
  /**
   * @description
   * Данный флаг нужно передавать в true, если команда была вызвана ЯВНО юзером
   * (например через клавишу плей/пауза, которая являеться частью вашего ресурса, а не плеера)
   * Это нужно, для корректного подсчета аналитики смотрения
   */
  manual?: boolean;
}

export type PlayerInstancePauseCommandOptions = PlayerInstanceAbstractCommandOptions;

export type PlayerInstancePlayCommandOptions = PlayerInstanceAbstractCommandOptions;

export interface PlayerInstanceSeekCommandOptions extends PlayerInstanceAbstractCommandOptions {
  /**
   * @description
   *
   * Означает, что это первый запуск + перемотка плеера (обычно, когда мы только запускаем контент), например из watching_item.
   */
  coldStart?: boolean;
  offset: number;
}

export interface PlayerInstanceLoadSourceOptions {
  /**
   * @description
   *  Ссылка в формате .m3u8 или `.mp4`
   */
  src: string;
  /**
   * Айди контента (фильма, или текущей серии) (опционально)
   */
  id?: string;
  /**
   * Время старта проигрывания (после запуска)
   */
  offset?: TimeSeconds;
}
