<template>
  <div
    :class="{
      [$style.icon]: true,
      [$style.iconSmartTv]: isSmartTV,
    }"
    :style="{
      width: toPixel(width as number),
      height: toPixel(height as number),
    }"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { toPixel } from '@package/sdk/src/core';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { isClient } from '@vueuse/core';

withDefaults(
  defineProps<{
    width?: number;
    height?: number;
  }>(),
  {
    width: () => (isClient && window.$vijuPlayer?.isSmartTV ? 60 : 24),
    height: () => (isClient && window.$vijuPlayer?.isSmartTV ? 60 : 24),
  },
);

const { isSmartTV } = usePlatform();
</script>

<style lang="scss" module>
@import '@package/ui/src/styles/adjust-smart-px';

.icon {
  display: inline-block;
  line-height: 1em;
  color: currentColor;
  user-select: none;
  vertical-align: initial;
  flex-shrink: 0;
  text-align: center;
}

.iconSmartTv {
  width: adjustPx(60px);
  height: adjustPx(60px);
}
</style>
