export enum Endpoints {
  RcTvSessionState = '/api/v2/tvs/:id/state',
  RcTokenGenerate = '/api/v2/centrifugo/tokens/generate',
  UsersCurrent = '/api/v1/stub/users/current',

  AuthSessionId = '/api/v1/sessions/:id',
  PaymentsQR = '/api/v1/payments/qr',
  AuthCheckToken = '/api/v1/smart_tv_sessions/check',
  AuthCreateToken = '/api/v1/smart_tv_sessions',
  AuthRefreshToken = '/api/v1/sessions/refresh',
  AuthSendOtp = '/api/v1/passwords/send_otp',
  AuthSendToken = '/api/v1/passwords/send_token',
  AuthSignIn = '/api/v1/sessions',
  AuthSignUp = '/api/v1/sign_up',
  CatalogFilterCountries = '/api/v1/countries',
  CatalogFilterGenres = '/api/v1/genres',
  ContentMoments = '/api/v1/contents/:id/moments',
  ContinueWatchingItems = '/api/v1/contents/continue_watch',
  ContentColdRecommendations = '/api/v1/contents/cold_recommendations',
  ContentPersonalRecommendations = '/api/v1/contents/personal_compilation',
  /**
   * @deprecated
   *
   * @use {Endpoints.ContinueWatchingItems}
   *
   * @type {Endpoints.ContentWatchingItemsContinue}
   */
  ContentWatchingItemsContinue = '/api/v1/content/watching_items/continue_watch',
  ProfilesWatchingItemContent = '/api/v1/watching/profiles/:profile_id/contents/:content_id',
  Content = '/api/v1/contents',
  ContentMovies = '/api/v1/content/movies',
  ContentSerials = '/api/v1/content/serials',
  ContentMovieManifest = '/api/v1/content/movies/:id/stream_manifest',
  ContentSerialManifest = '/api/v1/content/episodes/:id/stream_manifest',
  ContentMoviesId = '/api/v1/content/movies/:id?platform=web',
  ContentSerialsId = '/api/v1/content/serials/:id?platform=web',
  ContentSimilar = '/api/v1/contents/:id/similar',
  ContentCollections = '/api/v1/contents/:id/collections',
  ContentCollectionsInfo = '/api/v1/collections/:id',
  ContentRecommendationsRandom = '/api/v1/contents/recommendations/random',
  Channels = 'api/v1/tv/channels',
  ChannelsPlaylist = 'api/v1/tv/channels/:id/playlist',
  CollectionItems = '/api/v1/users/collection/items',
  MomentsFeed = '/api/v1/moments_feed',
  MomentId = '/api/v1/moments/:id',
  Playlists = '/api/v1/playlists',
  MomentsReactions = '/api/v1/moments/multiple_likes',
  OfferInfo = '/api/v1/frontend_pages/smart_tv/offers_general_payment_screen',
  OffersAll = '/api/v1/offers',
  OffersInfo = '/api/v1/frontend_pages/smart_tv/offers_general_info_lk',
  ParentalCheckControl = 'api/v1/parental_control/check_code',
  ParentalControl = 'api/v1/parental_control',
  PagesMain = '/api/v1/main_page',
  QuizGenres = '/api/v1/quiz_genres',
  QuizGenresContents = '/api/v1/quiz_genres/contents',
  SearchByText = '/api/v1/contents/semantic_search',
  SearchHistory = '/api/v1/search_histories',
  SubscriptionToggleAutoRenew = 'api/v1/subscriptions/:id/toggle_auto_renew',
  UserCurrent = '/api/v1/users/current',
  UserUpdate = '/api/v1/users',
  UserPresence = '/api/v1/users/presence',
  UserUpdateProfile = '/api/v1/sessions/current/change_profile',
  Features = '/toggles/',
}
