import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { PlayerInstanceConstructorOptions } from '@PLAYER/player/modules/instance/interfaces';
import { validateConfig } from '@PLAYER/player/versions/web/modules/config/validate-config';
import { inject } from 'vue';

export default function useSafeAppConfig() {
  const config = inject<PlayerInstanceConstructorOptions>('app.config');

  if (!config) {
    throw new UnexpectedComponentStateError('app.config');
  }

  return validateConfig(config);
}
