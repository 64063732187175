import { isClient } from '@vueuse/core';

interface LocalStorageValueMap {
  'smart.player.onboarding': boolean;

  'common.player.settings.disposeChromecastTranslationOnUnload': boolean;
  'common.player.settings.rememberLastQualityLevel': boolean;
  'common.player.settings.fastTap': boolean;

  'common.player.settings.lastQualityLevel': 'auto' | number;

  'common.player.settings.fingerRewind': boolean;

  'common.player.settings.rewindOnboardingTimesShown': number;
}

export enum LocalStorageKey {
  SmartTvOnboardingControl = 'smart.player.onboarding',
  DisposeChromecastOnUnload = 'common.player.settings.disposeChromecastTranslationOnUnload',
  RememberLastQualityLevel = 'common.player.settings.rememberLastQualityLevel',
  LastQualityLevel = 'common.player.settings.lastQualityLevel',
  IsFastTapPlaybackEnabled = 'common.player.settings.fastTap',
  IsFingerFastRewindEnabled = 'common.player.settings.fingerRewind',
  RewindOnboardingTimesShown = 'common.player.settings.rewindOnboardingTimesShown',
}

export type LocalStorageValue<K extends LocalStorageKey> = {
  value: LocalStorageValueMap[K];
  expires?: number;
};

interface PlayerLocalStorage {
  remove(key: LocalStorageKey): void;

  setValue(key: LocalStorageKey, item: LocalStorageValue<typeof key>): void;

  getValue(key: LocalStorageKey, defaultValue: LocalStorageValueMap[typeof key]): LocalStorageValue<typeof key>;
}

const mockLocalStorage: PlayerLocalStorage = {
  remove(_: LocalStorageKey) {
    //
  },
  setValue(_: LocalStorageKey, __: LocalStorageValue<typeof _>) {
    //
  },
  getValue(_: LocalStorageKey, __: LocalStorageValueMap[typeof _]): LocalStorageValue<typeof _> {
    return { value: __ };
  },
};

export function useLocalStorage(): PlayerLocalStorage {
  if (!isClient) {
    return mockLocalStorage;
  }

  const remove = (key: LocalStorageKey): void => {
    window.localStorage.removeItem(key);
  };

  const setValue = (key: LocalStorageKey, item: LocalStorageValue<typeof key>) => {
    const expires = Date.now() + (item.expires || 1000) * 1000;
    const stringified = JSON.stringify({ ...item, expires: item.expires ? expires : undefined });

    window.localStorage.setItem(key, stringified);
  };

  const getValue = (
    key: LocalStorageKey,
    defaultValue: LocalStorageValueMap[typeof key],
  ): LocalStorageValue<typeof key> => {
    try {
      const item = window.localStorage.getItem(key);

      if (!item) {
        return {
          value: defaultValue,
        };
      }

      const value: LocalStorageValue<typeof key> = JSON.parse(item);

      // Если поле не было установлено, значит считаем что ключ хранится "вечно"
      if (!value.expires) {
        return value;
      }

      const now = Date.now();

      // Значение устарело
      if (now > value.expires) {
        remove(key);
        return {
          value: defaultValue,
        };
      }

      return value;
    } catch (err) {
      throw new TypeError(`Error when parse JSON value with key - ${key}.`);
    }
  };

  return {
    remove,
    setValue,
    getValue,
  };
}
