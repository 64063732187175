import { DsmlRequestBodyV2Params } from '../code/dsml-v2-request-transformer';
import getActualToken from '../code/get-actual-token';
import { logger } from '../code/logger';
import { AbstractSendEventRequest } from './abstract-send-event-request';
import { ENDPOINTS, getBaseUrl } from './endpoint';

class SendEventsBatchRequest extends AbstractSendEventRequest {
  public constructor(protected readonly payload: unknown) {
    super(payload);
  }

  protected get url() {
    return `${getBaseUrl()}/${ENDPOINTS.EVENTS_BATCH}`;
  }
}

export async function sendEventsBatch(events: DsmlRequestBodyV2Params[]): Promise<void> {
  const token = await getActualToken();

  const request = new SendEventsBatchRequest(events);
  request.setToken(token);

  try {
    await request.send();
  } catch (error) {
    logger?.error(`Cannot send batch events: ${error}`);
  }
}
