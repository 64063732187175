<template>
  <picture>
    <source :srcset="srcSet" />
    <img
      :style="{
        backgroundColor: backgroundColor,
      }"
      :width="width"
      :height="height"
      :src="normalizedSrc"
      :class="imageClass"
      :loading="loading"
      :alt="alt"
      @error="onError"
    />
  </picture>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    src?: string;
    alt?: string;
    imageClass?: string | Record<string, boolean>;
    width?: number;
    backgroundColor?: string;
    height?: number;
    loading?: 'eager' | 'lazy';
  }>(),
  {
    width: 250,
    loading: 'lazy',
    src: '',
    alt: '',
  },
);

const hasError = ref(false);
const onError = () => {
  hasError.value = true;
};

const RETINA_SIZES = ['2x', '3x'];

const normalizedSrc = computed(() => {
  const src = props.src;

  if (!src || hasError.value) {
    return '';
  }

  if (!props.width) {
    return src;
  }

  return `${src}?w=${props.width}`;
});

const srcSet = computed(() =>
  RETINA_SIZES.reduce((a, b, currentIndex) => {
    const normalizedIndex = currentIndex + 2;

    const sizeSrc = `${props.src}?w=${props.width * normalizedIndex} ${b}`;
    const _a = a || normalizedSrc.value;

    return _a + ', ' + sizeSrc;
  }, ''),
);
</script>
