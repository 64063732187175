<template></template>

<script lang="ts" setup>
import { isTrue } from '@package/sdk/src/core';
import { IntersectionEvent, VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useExperimentalFeature from '@PLAYER/player/modules/experimental-feature/use-experimental-feature';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useLogger from '@PLAYER/player/modules/global/use-logger';
import { useLoaderActions } from '@PLAYER/player/modules/hooks/use-loader-actions';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useSafeGetMediaSourceTech from '@PLAYER/player/modules/hooks/use-safe-get-media-source-tech';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import {
  MediaSourceTechEvent,
  MediaSourceTechEventError,
  MediaSourceTechEventFragmentChanged,
  MediaSourceTechEventFragmentLoaded,
} from '@PLAYER/player/tech/events/media-source-tech-event';
import { ref, watch } from 'vue';

const { requestLoader, releaseLoader } = useLoaderActions();
const { isKinom } = useProjector();
const { isViewportIntersectionPlayback } = useExperimentalFeature();

const manifestStore = useManifestStore();
const externalEventBus = useSafeExternalEventBus();
const eventBus = useSafeEventBus();
const logger = useLogger();

const getMediaSourceTech = useSafeGetMediaSourceTech();

const isBuffering = ref(false);
watch(isBuffering, (curr, prev) => {
  if (curr === prev) {
    return;
  }

  return isTrue(curr) ? requestLoader('light') : releaseLoader();
});

const onFragmentBuffered = () => {
  isBuffering.value = false;
};

const onError = (event: MediaSourceTechEvent<MediaSourceTechEventError>) => {
  const { errorType } = event.data;

  if (errorType === 'buffer-error') {
    isBuffering.value = true;
  }
};

const onFragmentChanged = (event: MediaSourceTechEvent<MediaSourceTechEventFragmentChanged>) => {
  const { currentTime, startFragmentTime } = event.data;

  manifestStore.setCustomManifestVideoCurrentTime(currentTime);
  manifestStore.setCustomManifestFragmentStart(startFragmentTime);

  isBuffering.value = false;
};

const onFragmentLoaded = (event: MediaSourceTechEvent<MediaSourceTechEventFragmentLoaded>) => {
  const { levelTotalDuration } = event.data;

  manifestStore.setCustomManifestTotalDuration(levelTotalDuration);
};

const onIntersect = (event: VideoPlayerExternalEvent<IntersectionEvent>) => {
  if (!isViewportIntersectionPlayback.value || !event.data.intersect || !isKinom.value) {
    return;
  }

  logger.info('MediaSourceLoaderManager#startLoad - intersect', event.data);

  const mediaSourceTech = getMediaSourceTech();

  mediaSourceTech.startLoad();
};

eventBus.on('onPlayerInit', () => externalEventBus.on('intersect', onIntersect));

useEffect(() => {
  const mediaSourceTech = getMediaSourceTech();

  mediaSourceTech.on('fragment-loaded', onFragmentLoaded);
  mediaSourceTech.on('fragment-changed', onFragmentChanged);
  mediaSourceTech.on('fragment-buffered', onFragmentBuffered);
  mediaSourceTech.on('error', onError);

  return () => {
    mediaSourceTech.off('fragment-loaded', onFragmentLoaded);
    mediaSourceTech.off('fragment-changed', onFragmentChanged);
    mediaSourceTech.off('fragment-buffered', onFragmentBuffered);
    mediaSourceTech.off('error', onError);
  };
});
</script>
