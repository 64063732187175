<template>
  <video-controls-hideable-view :class="$style.smartVideoControlsTimelineLayer">
    <root-video-z-index-layer data-video-interaction :class="$style.controlsLayer">
      <div v-show="isTimelinePlaybackShown" :class="$style.timeline">
        <timeline-playback-buttons v-show="isTimelinePlaybackButtonsShown" />
        <timeline-playback
          :ref="(comp) => (timelineEl = comp?.$el)"
          :current-time="normalizedDisplayedCurrentTime"
          :duration="normalizedDuration"
          :buffer-length="bufferInfo.length"
          :buffer-start="bufferInfo.start"
          :is-timeline-hover-handle-shown="false"
          :is-timeline-playback-buttons-shown="true"
          :current-handle-position="currentHandlePosition"
          :class="$style.playback"
        />
        <vod-playback-time-view
          v-show="isShownPlaybackTime"
          :duration-css-class-name="$style.durationCssClassName"
          :current-time-css-class-name="$style.currentTimeCssClassName"
        />
      </div>
      <transition name="fade">
        <smart-tv-video-controls v-if="isControlsShown" />
      </transition>
      <transition name="fade">
        <kinom-metadata-player-controller
          v-if="isKinomMetadataControllerShown"
          :buffer-length="0"
          :buffer-start="0"
          :loop="loop"
        />
      </transition>
    </root-video-z-index-layer>
  </video-controls-hideable-view>
</template>

<script lang="ts" setup>
import { TimeSeconds } from '@PLAYER/player/base/number';
import VideoControlsHideableView from '@PLAYER/player/components/controls/VideoControlsHideableView.vue';
import VodPlaybackTimeView from '@PLAYER/player/components/controls/VodPlaybackTimeView.vue';
import KinomMetadataPlayerController from '@PLAYER/player/components/timeline/KinomMetadataPlayerController.vue';
import TimelinePlayback from '@PLAYER/player/components/timeline/TimelinePlayback.vue';
import TimelinePlaybackButtons from '@PLAYER/player/components/timeline/TimelinePlaybackButtons.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import usePlaybackAnalytics from '@PLAYER/player/modules/analytics/use-skip-playback-analytics';
import useStreamingAnalytics from '@PLAYER/player/modules/analytics/use-streaming-analytics';
import useTimelineAnalytics from '@PLAYER/player/modules/analytics/use-timeline-analytics';
import usePlaybackVariables from '@PLAYER/player/modules/hooks/use-playback-variables';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoTimeline from '@PLAYER/player/modules/timeline/use-video-timeline';
import useVideoControlsVariables from '@PLAYER/player/modules/video/use-video-controls-variables';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import SmartTvVideoControls from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControls.vue';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

defineProps<{
  loop: boolean;
}>();

const videoUIStore = useVideoUIStore();
const videoInteractions = useVideoInteractions();
const playbackAnalytics = usePlaybackAnalytics();

const { isLive } = useProjector();

const { bufferInfo, prevDisplayedCurrentTime, isPlaying } = storeToRefs(videoUIStore);

const { isTimelinePlaybackShown, isKinomMetadataControllerShown } = usePlaybackVariables();

const { isControlsShown, isShownPlaybackTime, isTimelinePlaybackButtonsShown } = useVideoControlsVariables();

const { sendAutoStreamingEvent } = useStreamingAnalytics();
const { normalizedDuration, normalizedDisplayedCurrentTime, mediaSourceTechPlaybackType } = useVideoPlayerVariables();

const timelineEl = ref<HTMLElement>();

const { customManifestFragmentStart } = storeToRefs(useManifestStore());

const { currentHandlePosition, lastTimeChangedManually } = useVideoTimeline(timelineEl, {
  onStartCallback: () => {
    if (isPlaying.value) {
      sendAutoStreamingEvent();
    }

    playbackAnalytics.setFinishTimeStreaming(normalizedDisplayedCurrentTime.value);
  },
  pause: videoInteractions.pause,
  play: videoInteractions.play,
  changeCurrentTime: (time: TimeSeconds) => {
    if (isLive.value && mediaSourceTechPlaybackType.value === 'hls') {
      return videoInteractions.changeCurrentTime({
        seconds: time + customManifestFragmentStart.value,
        manually: true,
      });
    }

    videoInteractions.changeCurrentTime({
      seconds: time,
      manually: true,
    });
  },
  duration: normalizedDuration,
  currentTime: normalizedDisplayedCurrentTime,
  isPlaying,
});

const timelineAnalytics = useTimelineAnalytics();

watch(lastTimeChangedManually, (val) => {
  // Обнуляем информацию о буффере.
  videoUIStore.setBufferInfo({ length: 0, start: 0 });
  timelineAnalytics.onTimelineTimeChangeManually(val, prevDisplayedCurrentTime.value);
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.controlsLayer {
  bottom: var(--g-spacing-32);
  z-index: var(--z-index-video-controls);
  display: flex;
  padding: 0 adjust.adjustPx(32px);
  width: 100%;
  flex-direction: column;
}

.smartVideoControlsTimelineLayer {
  --timeline-playback-buttons-bottom-pos: var(--g-spacing-24);
}

.currentTimeCssClassName {
  @include smartTvFonts.SmartTvCaption-1();
}

.durationCssClassName {
  @include smartTvFonts.SmartTvCaption-1();
}

.playback {
  margin-right: var(--g-spacing-12);
  height: 0px;
}

.timeline {
  position: relative;
  z-index: 0;
  display: flex;
  margin-bottom: var(--g-spacing-12);
  align-items: center;
}
</style>
