<template>
  <section :class="$style.controls">
    <h3 :class="$style.title">
      {{ $t('smart.onboarding.title') }}
    </h3>
    <timeline-playback
      :ref="(comp) => (timelineEl = comp?.$el)"
      :current-time="currentTime"
      :duration="fakeOnboardingTimelineDuration"
      :current-handle-position="currentHandlePosition"
      :is-timeline-playback-buttons-shown="false"
      :is-timeline-hover-handle-shown="false"
      :class="$style.timeline"
      :buffer-start="0"
      :buffer-length="0"
    />
    <div :class="$style.buttonsWrapper">
      <div :class="$style.block">
        <smart-tv-remote-control :class="$style.remote" is-active-center />
        <p :class="$style.description">{{ $t('smart.onboarding.playAndPause') }}</p>
      </div>

      <div :class="$style.block">
        <smart-tv-remote-control :class="$style.remote" is-active-left is-active-right />
        <p :class="$style.description">{{ $t('smart.onboarding.playback') }}</p>
      </div>

      <div :class="$style.block">
        <smart-tv-remote-control :class="$style.remote" is-active-down />
        <p :class="$style.description">
          {{ isLive ? $t('smart.onboarding.tvChannels') : $t('smart.onboarding.series') }}
        </p>
      </div>
    </div>
    <app-button
      :ref="(comp) => (buttonEl = comp?.$el)"
      :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerRemotePopup)"
      variation="smart-player-button-primary"
      :text="$t('common.clear')"
      :on-click="onPopupClose"
    />
  </section>
</template>

<script lang="ts" setup>
import { DisposableStore } from '@package/sdk/src/core';
import { getActiveDocumentElement } from '@PLAYER/player/base/dom';
import { LocalStorageKey, useLocalStorage } from '@PLAYER/player/base/local-storage';
import TimelinePlayback from '@PLAYER/player/components/timeline/TimelinePlayback.vue';
import AppButton from '@PLAYER/player/components/ui/AppButton.vue';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useKeyboardHandler from '@PLAYER/player/modules/hooks/use-keyboard-handler';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import { SmartTvCode } from '@PLAYER/player/modules/keyboard/smart-tv-key-codes';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import {
  SmartTvPlayerNavigatableGroupName,
  useNavigatableMap,
} from '@PLAYER/player/versions/smart/modules/smart-navigation/use-navigatable-map';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import SmartTvRemoteControl from '../controls/SmartTvRemoteControl.vue';

const { isLive } = useProjector();
const keyboard = useKeyboardHandler();
const layoutStore = useLayoutStore();
const eventBus = useSafeEventBus();
const localStorage = useLocalStorage();
const { getNavigationAttribute } = useNavigatableMap();

const currentTime = ref(100);
const fakeOnboardingTimelineDuration = 1200;
const timelineEl = ref<HTMLElement>();

const currentHandlePosition = computed(() => currentTime.value / fakeOnboardingTimelineDuration);

const disposableStore = new DisposableStore();

const isActiveElementHandle = () => {
  const activeElement = getActiveDocumentElement();

  return activeElement?.getAttribute('data-navigatable') === SmartTvPlayerNavigatableGroupName.PlayerControlsHandle;
};

const setCurrentTime = (newTime: number) => {
  if (newTime < 0) {
    currentTime.value = 0;
    return;
  }

  if (newTime >= fakeOnboardingTimelineDuration) {
    currentTime.value = 1200;
    return;
  }

  currentTime.value = newTime;
};

disposableStore.add(
  keyboard.on(SmartTvCode.Right, () => {
    if (!isActiveElementHandle()) {
      return;
    }

    setCurrentTime(currentTime.value + 60);
  }),
);

disposableStore.add(
  keyboard.on(SmartTvCode.Left, () => {
    if (!isActiveElementHandle()) {
      return;
    }

    setCurrentTime(currentTime.value - 60);
  }),
);

const onPopupClose = () => {
  localStorage.setValue(LocalStorageKey.SmartTvOnboardingControl, {
    value: true,
  });

  eventBus.emit('onOnboardingPassed');
  layoutStore.setPopup(null);
};

const buttonEl = ref<HTMLElement>();

onMounted(() => {
  if (!buttonEl.value) {
    return;
  }

  window.$setActiveNavigationItem(buttonEl.value);
});

onBeforeUnmount(() => {
  disposableStore.dispose();
});
</script>

<style module lang="scss">
@import '@package/ui/src/styles/adjust-smart-px';

.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--color-bg-primary);
}

.title {
  margin-bottom: adjustPx(93px);
  font-weight: 500;
  font-size: adjustPx(48px);
}

.timeline {
  margin-bottom: adjustPx(75px);
  width: 70%;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: adjustPx(132px);
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block:not(:last-child) {
  margin-right: adjustPx(85px);
}

.remote {
  margin-bottom: adjustPx(61px);
  width: adjustPx(206px);
  height: adjustPx(206px);
}

.description {
  max-width: adjustPx(312px);
  font-weight: 400;
  font-size: adjustPx(36px);
  line-height: 110%;
  text-align: center;
}

.proceed {
  padding: adjustPx(23px) adjustPx(50px);
  border-radius: 45px;
  border: none;
  background-color: var(--color-notheme-bg-accent);
  font-weight: 500;
  font-size: adjustPx(36px);
  color: var(--color-notheme-text-primary);

  &:focus {
    background-color: var(--color-states-bg-accent-hover);
  }
}
</style>
