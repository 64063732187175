<template>
  <app-sdk-button
    :ref="(comp) => (buttonEl = comp?.$el)"
    :class="$style.playerButton"
    :text="text"
    :variation="variation"
    :size="size"
  >
    <template #icon>
      <slot name="icon"></slot>
    </template>
  </app-sdk-button>
</template>

<script lang="ts" setup>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import AppSdkButton from '@package/ui/src/components/AppButton.vue';
import type { AppButtonVariation } from '@package/ui/src/types/button-types';
import { AnyFunction } from '@PLAYER/player/base/function';
import useKeyboardShortcuts from '@PLAYER/player/modules/keyboard/use-keyboard-shortcuts';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    onClick?: AnyFunction;
    text: string;
    size?: 'small' | 'medium' | 'large';
    variation?: AppButtonVariation;
  }>(),
  {
    variation: 'player-button-primary',
    onClick: () => () => ({}),
  },
);

const buttonEl = ref<HTMLButtonElement>();
const { onSmartTvKeydown } = useKeyboardShortcuts();

const onKeydown = onSmartTvKeydown(props.onClick);

onMounted(() => {
  if (!buttonEl.value) {
    throw new UnexpectedComponentStateError('buttonEl');
  }

  buttonEl.value.addEventListener('click', props.onClick);
  buttonEl.value.addEventListener('keydown', onKeydown);
});

onBeforeUnmount(() => {
  if (!buttonEl.value) {
    throw new UnexpectedComponentStateError('buttonEl');
  }

  buttonEl.value.removeEventListener('click', props.onClick);
  buttonEl.value.removeEventListener('keydown', onKeydown);
});
</script>

<style lang="scss" module>
.playerButton {
  pointer-events: all;
}
</style>
