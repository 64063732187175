import { Endpoints } from '../endpoints';
import type { EnvironmentService } from '../environment/environment-service';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';
import type { IGenerateQRCodeParams, IGenerateQRCodeResponse } from './payments';

export class PaymentsService {
  constructor(
    private readonly $environment: EnvironmentService,
    private readonly $http: RequestService,
  ) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public async generateQrCode(params: IGenerateQRCodeParams) {
    const { data } = await this.$http.request<IGenerateQRCodeResponse>(
      {
        method: HTTPRequestMethod.Post,
        url: Endpoints.PaymentsQR,
        data: {
          session_id: params.sessionId,
          offer_id: params.offerId,
          ...(params.email ? { email: params.email } : {}),
          ...(params.phone ? { phone: params.phone } : {}),
        },
      },
      {
        withToken: true,
      },
    );

    return data.payload;
  }
}
