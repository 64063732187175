// @ts-nocheck

import { isUndefined, throttleWithImmediate, UnexpectedComponentStateError } from '../../../core';
import { TvKeyCode } from '../../../core/keyboard/tv-keys';
import { Disposable } from '../../../core/lifecycle/disposable';
import { AppKeyboardEvent, keyboardEventHandler } from '../../navigation/keyboard-event-handler';
import { AppWheelEvent, mouseEventHandler } from '../../navigation/mouse-event-handler';
import { ConstantsConfig } from '../../utils/constants-storage';
import type { EnvironmentService } from '../environment/environment-service';
import type { InternalRef } from '../global-settings';
import { globalSettings } from '../index';

const NAVIGATABLE = 'navigatable';
const INDEX = 'index';
const LOOP = 'loop';
const SPECIAL_GROUPS = ['error', 'modal', 'player-editor-controls'];

declare global {
  interface Window {
    $setActiveNavigationItem: unknown;
    $setOnPressBackCallback: unknown;
  }
}

export namespace AppNavigation {
  export type Direction = 'up' | 'down' | 'right' | 'left';

  export type NavigationMapItem = { group: string; startWith?: string | number };
  export type NavigationMap = Array<NavigationMapItem | NavigationMapItem[]>;

  export enum Directions {
    Up = 'up',
    Down = 'down',
    Right = 'right',
    Left = 'left',
  }

  export interface WheelAction {
    dec: Direction;
    inc: Direction;
  }

  export type OnInactiveFunc = (group: string | null) => void;

  // calculate distance to the nearest point

  export const filterElements = (
    direction: Direction,
    elements: Array<HTMLElement | HTMLInputElement>,
    activeElementRect: DOMRect,
    activeElement: HTMLElement,
    activeElementGroup: string,
    navigationMap?: NavigationMap | null,
  ) => {
    const activeElementRectTop = Math.floor(activeElementRect.top);
    const activeElementRectLeft = Math.floor(activeElementRect.left);

    let availableGroups: NavigationMapItem[] = [];
    const navigatableGroupIndex = navigationMap?.findIndex((item) =>
      Array.isArray(item) ? item.find((x) => x.group === activeElementGroup) : item.group === activeElementGroup,
    );

    if (navigationMap && navigatableGroupIndex > -1 && ['down', 'up'].includes(direction)) {
      const dir = direction === 'down' ? 1 : -1;
      const activeItem = navigationMap[navigatableGroupIndex];
      if (Array.isArray(activeItem)) {
        const nextItemIndex = activeItem.findIndex((x) => x.group === activeElementGroup);

        availableGroups.push(activeItem[nextItemIndex + dir], activeItem[nextItemIndex]);
      } else {
        availableGroups.push(navigationMap[navigatableGroupIndex + dir], navigationMap[navigatableGroupIndex]);
      }
    }
    availableGroups = availableGroups?.filter(Boolean);

    return elements.filter((element) => {
      if (element === activeElement || (element as HTMLInputElement).disabled) {
        return false;
      }

      const group = element.dataset[NAVIGATABLE] || '';
      const loop = activeElement.dataset[LOOP] || '';

      const mappedGroup = availableGroups?.find((item) => item.group === group);
      if (availableGroups.length && !mappedGroup) {
        return false;
      } else if (availableGroups.length && mappedGroup && !isUndefined(mappedGroup.startWith)) {
        const index = Number(element.dataset[INDEX] || '');
        if (index !== mappedGroup.startWith) {
          return false;
        }
      }

      if (loop && loop.includes(direction) && group !== activeElementGroup) {
        return false;
      }

      // don't leave modals during the navigation
      if (SPECIAL_GROUPS.includes(activeElementGroup) && !SPECIAL_GROUPS.includes(group)) {
        return false;
      }

      // don't open nav while pressing 'up' or 'down'
      if (activeElementGroup !== 'nav' && group === 'nav' && ['up', 'down'].includes(direction)) {
        return false;
      }

      const rect = element.getBoundingClientRect();
      const isUp = Math.floor(rect.top) < activeElementRectTop;
      const isDown = Math.floor(rect.top) > activeElementRectTop;
      const isRight = Math.floor(rect.left) > activeElementRectLeft;
      const isLeft = Math.floor(rect.left) < activeElementRectLeft;

      return (
        (direction === 'up' && isUp) ||
        (direction === 'down' && isDown) ||
        (direction === 'right' && isRight) ||
        (direction === 'left' && isLeft)
      );
    });
  };

  export const getBlockPosition = (direction?: Directions) => {
    if (!direction || [Directions.Down].includes(direction)) {
      return 'start';
    }

    if ([Directions.Up].includes(direction)) {
      return 'center';
    }

    if ([Directions.Left, Directions.Right].includes(direction)) {
      return 'nearest';
    }

    return 'start';
  };
}

export class AppNavigationService extends Disposable {
  public hasPreviousRoute: any;

  public activeNavigationItem: InternalRef<HTMLElement | undefined>;
  public activeNavigationGroup: InternalRef<string>;
  public activeNavigationDirection: InternalRef<string>;
  public navigationMap: NavigationMap | null;

  public wheelActions: AppNavigation.WheelAction = { dec: 'up', inc: 'down' };

  public onPressBackFn?: VoidFunction;
  public customScroll?: (element: HTMLElement) => void;
  public onNavigate?: AppNavigation.OnInactiveFunc;

  private isNavigationEnabled = true;
  private lastActions: {
    element?: HTMLElement | null;
    direction?: AppNavigation.Directions | null;
    group?: string | null;
    position?: number | null;
  }[] = [];

  constructor(private readonly environmentService: EnvironmentService) {
    super();
  }

  public setNavigationMap(navigationMap: NavigationMap) {
    this.navigationMap = navigationMap;
  }

  public disableNavigation() {
    this.isNavigationEnabled = false;
  }

  public enableNavigation() {
    this.isNavigationEnabled = true;
  }

  public setWheelAction = (actions: AppNavigation.WheelAction) => {
    this.wheelActions = actions;
  };

  public setOnNavigate = (callback?: AppNavigation.OnInactiveFunc) => {
    this.onNavigate = callback;
  };

  public clearLastActions() {
    this.lastActions = [];
  }

  public setOnPressBackCallback = (callback?: VoidFunction, reset = false) => {
    const prevValue = this.onPressBackFn;

    if (callback && reset) {
      this.onPressBackFn = () => {
        callback();
        this.onPressBackFn = prevValue;
      };
    } else {
      this.onPressBackFn = callback;
    }
  };

  public setCustomScroll = (callback?: (element: HTMLElement) => void, reset = false) => {
    const prevValue = undefined;

    if (callback && reset) {
      this.customScroll = (element: HTMLElement) => {
        callback(element);
        this.onPressBackFn = prevValue;
      };
    } else {
      this.customScroll = callback;
    }
  };

  public setActiveNavigationItem = (
    element?: HTMLElement,
    direction?: AppNavigation.Directions,
    onScroll?: typeof this.customScroll,
  ): void => {
    if (globalSettings.vueVersion === 'vue2') {
      return;
    }

    if (!element) {
      if (this.environmentService.getVariable('isStrictMode')) {
        throw new UnexpectedComponentStateError('element');
      }

      return;
    }

    const group = element?.getAttribute('data-navigatable');
    this.lastActions.unshift({ element, direction, group: element?.getAttribute('data-navigatable') });
    if (this.lastActions?.length > 2) {
      this.lastActions = this.lastActions.slice(0, 2);
    }

    this.activeNavigationItem.value = element;
    this.activeNavigationGroup.value = group || '';
    this.activeNavigationDirection.value = direction || '';

    if (!element?.focus) {
      if (this.environmentService.getVariable('isStrictMode')) {
        throw new UnexpectedComponentStateError('element.focus');
      }

      return;
    }

    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        if (onScroll) {
          onScroll(element);
        } else if (this.customScroll) {
          this.customScroll(element);
        } else {
          // disable force scroll
          element.focus({ preventScroll: false });
          element.scrollIntoView({
            behavior: 'smooth',
            block: AppNavigation.getBlockPosition(direction),
            inline: 'center',
          });
        }

        if (this.onNavigate) {
          this.onNavigate(group);
        }
      });
    }, 50);
  };

  public async navigateTo(options: { element?: HTMLElement; group?: string; position?: number; timeoutMs?: number }) {
    if (globalSettings.vueVersion === 'vue2') {
      return;
    }

    const { element, group, position, timeoutMs } = options;

    const callback = async () => {
      let el = element;

      if (group) {
        el = await this.getNavigationItem(group, position);
      }

      return this.setActiveNavigationItem(el);
    };

    if (timeoutMs) {
      return new Promise<void>((resolve) => {
        window.setTimeout(() => {
          callback();
          resolve();
        }, timeoutMs);
      });
    }

    return callback();
  }

  public async onRightDirection(event: AppKeyboardEvent) {
    event.preventDefault();
    const element = await this.smartNavigate(AppNavigation.Directions.Right, this.activeNavigationItem.value);

    this.setActiveNavigationItem(element, AppNavigation.Directions.Right);
  }

  public async onLeftDirection(event: AppKeyboardEvent) {
    event.preventDefault();

    const element = await this.smartNavigate(AppNavigation.Directions.Left, this.activeNavigationItem.value);

    this.setActiveNavigationItem(element, AppNavigation.Directions.Left);
  }

  public async onDownDirection(event: AppKeyboardEvent) {
    event.preventDefault();
    const element = await this.smartNavigate(AppNavigation.Directions.Down, this.activeNavigationItem.value);

    this.setActiveNavigationItem(element, AppNavigation.Directions.Down);
  }

  public async onUpDirection(event: AppKeyboardEvent) {
    event.preventDefault();
    const element = await this.smartNavigate(AppNavigation.Directions.Up, this.activeNavigationItem.value);

    this.setActiveNavigationItem(element, AppNavigation.Directions.Up);
  }

  public onEnter(event: AppKeyboardEvent) {
    const target = event.domEvent.target as HTMLElement;

    if (target.tagName?.toLowerCase() === 'a') {
      event.domEvent.target?.dispatchEvent(new Event('click'));
    }
  }

  public async onWheel(event: AppWheelEvent) {
    event.preventDefault();

    if (event.domEvent.deltaY < 0) {
      const element = await this.smartNavigate(this.wheelActions.dec, this.activeNavigationItem.value);

      return this.setActiveNavigationItem(element, AppNavigation.Directions.Up);
    }

    if (event.domEvent.deltaY > 0) {
      const element = await this.smartNavigate(this.wheelActions.inc, this.activeNavigationItem.value);

      this.setActiveNavigationItem(element, AppNavigation.Directions.Down);
    }
  }

  public init() {
    this.hasPreviousRoute = globalSettings.ref(false);
    this.activeNavigationItem = globalSettings.ref();
    this.activeNavigationGroup = globalSettings.ref('');
    this.activeNavigationDirection = globalSettings.ref('');
    this.navigationMap = null;

    const appNavigationThrottleTimeoutMs = ConstantsConfig.getProperty('appNavigationThrottleTimeoutMs');

    if (globalSettings.vueVersion === 'vue3') {
      keyboardEventHandler.on(
        TvKeyCode.UP,
        throttleWithImmediate(this.onUpDirection.bind(this), { timeout: appNavigationThrottleTimeoutMs }),
      );
      keyboardEventHandler.on(
        TvKeyCode.DOWN,
        throttleWithImmediate(this.onDownDirection.bind(this), { timeout: appNavigationThrottleTimeoutMs }),
      );
      keyboardEventHandler.on(
        TvKeyCode.LEFT,
        throttleWithImmediate(this.onLeftDirection.bind(this), { timeout: appNavigationThrottleTimeoutMs }),
      );
      keyboardEventHandler.on(
        TvKeyCode.RIGHT,
        throttleWithImmediate(this.onRightDirection.bind(this), { timeout: appNavigationThrottleTimeoutMs }),
      );

      keyboardEventHandler.on(TvKeyCode.ENTER, this.onEnter.bind(this));
    }

    mouseEventHandler.on(
      'wheel',
      throttleWithImmediate(this.onWheel.bind(this), { timeout: appNavigationThrottleTimeoutMs }),
    );

    window.$setActiveNavigationItem = this.setActiveNavigationItem;
    window.$setOnPressBackCallback = this.setOnPressBackCallback;
  }

  public async checkCanGoBack(_to, _from, next) {
    this.hasPreviousRoute.value = window.history.length > 2;

    if (next) {
      next();
    }
  }

  public async getNavigationItems(filter?: string): Promise<HTMLElement[]> {
    await globalSettings.nextTick();

    const elements = [...Array.from(document.querySelectorAll('[data-navigatable]'))] as HTMLElement[];

    return elements.filter((element) =>
      filter ? element.dataset.navigatable?.includes(filter) : true,
    ) as HTMLElement[];
  }

  public async getNavigationItem(group: string, index?: number) {
    await globalSettings.nextTick();

    const element = [...Array.from(document.querySelectorAll(`[data-navigatable="${group}"]`))]?.[index || 0];

    if (!element && this.environmentService.getVariable('isStrictMode')) {
      // app does not catch these errors
      // throw new UnexpectedComponentStateError('element');
      return;
    }

    return element as HTMLElement;
  }

  public async getNavigationItemByKey(group: string, key: string) {
    await globalSettings.nextTick();

    const element = document.querySelector(`[data-navigatable="${group}"][data-navigation-key="${key}"]`);

    if (!element && this.environmentService.getVariable('isStrictMode')) {
      return;
    }

    return element as HTMLElement;
  }

  public async getNavigationItemIndex(group: string, target?: HTMLElement) {
    if (!target) return -1;

    await globalSettings.nextTick();

    return Array.from(document.querySelectorAll(`[data-navigatable="${group}"]`)).findIndex((item) => item === target);
  }

  public getNavigationItemGroup(element?: HTMLElement) {
    return element?.getAttribute('data-navigatable') ?? '';
  }

  public async smartNavigate(direction: AppNavigation.Direction, activeElement?: HTMLElement) {
    if (!this.isNavigationEnabled) {
      console.warn('Navigation disabled');
      return;
    }

    const elements = await this.getNavigationItems();

    if (!activeElement && elements.length) {
      return elements[0];
    }

    if (!activeElement) {
      if (this.environmentService.getVariable('isStrictMode')) {
        console.error(new UnexpectedComponentStateError('activeElement'));
      }

      return;
    }

    const activeElementRect = activeElement.getBoundingClientRect();
    const activeElementGroup = activeElement.dataset[NAVIGATABLE];

    if (!activeElementGroup) {
      throw new UnexpectedComponentStateError('activeElementGroup');
    }

    const filtered = AppNavigation.filterElements(
      direction,
      elements,
      activeElementRect,
      activeElement,
      activeElementGroup,
      this.navigationMap,
    );
    const sorted = this.sortElements(direction, filtered, activeElementRect, activeElementGroup);

    return sorted[0] || activeElement;
  }

  private getDistance(rectA: DOMRect, rectB: DOMRect): number {
    const centerA = {
      x: (rectA.left || rectA.x) + rectA.width / 2,
      y: (rectA.top || rectA.y) + rectA.height / 2,
    };

    const centerB = {
      x: (rectB.left || rectB.x) + rectB.width / 2,
      y: (rectB.top || rectB.y) + rectB.height / 2,
    };

    return Math.sqrt((centerB.x - centerA.x) ** 2 + (centerB.y - centerA.y) ** 2);
  }

  private isElementActiveNavItem(element: HTMLElement) {
    return Array.from(element.classList).includes('active');
  }

  private sortElements(
    direction: AppNavigation.Directions,
    elements: HTMLElement[],
    activeElementRect: DOMRect,
    activeElementGroup?: string,
  ) {
    return elements.slice().sort((a, b) => {
      const groupA: string = a.dataset[NAVIGATABLE] || '';
      const groupB: string = b.dataset[NAVIGATABLE] || '';

      const rectA = a.getBoundingClientRect();
      const rectB = b.getBoundingClientRect();

      if (activeElementGroup !== 'nav') {
        if (groupA === 'nav' && groupB !== 'nav') {
          // navigation to menu should have more priority
          // if element in other group and hidden
          if (activeElementGroup !== groupB && rectB.left < 0) return -1;

          return 1;
        }

        if (groupA !== 'nav' && groupB === 'nav') {
          // navigation to menu should have more priority
          // if element in other group and hidden
          if (activeElementGroup !== groupA && rectA.left < 0) return 1;

          return -1;
        }

        if (groupA === 'nav' && groupB === 'nav') {
          return this.isElementActiveNavItem(a) ? -1 : 1;
        }
      }

      // navigation in one group should have more priority
      if (activeElementGroup && groupA !== groupB && [groupA, groupB].includes(activeElementGroup)) {
        return groupA === activeElementGroup ? -1 : 1;
      }

      const prevElement = this.lastActions[1]?.element;
      const prevDirection = this.lastActions[0]?.direction;

      // navigating to previous group should have more priority
      if (
        prevDirection === AppNavigation.Directions.Left &&
        direction === AppNavigation.Directions.Right &&
        (a === prevElement || b === prevElement)
      ) {
        return a === prevElement ? -1 : 1;
      }

      // navigating to previous group should have more priority
      if (
        prevDirection === AppNavigation.Directions.Right &&
        direction === AppNavigation.Directions.Left &&
        (a === prevElement || b === prevElement)
      ) {
        return a === prevElement ? -1 : 1;
      }

      // navigating to previous group should have more priority
      if (
        prevDirection === AppNavigation.Directions.Up &&
        direction === AppNavigation.Directions.Down &&
        (a === prevElement || b === prevElement)
      ) {
        return a === prevElement ? -1 : 1;
      }

      // navigating to previous group should have more priority
      if (
        prevDirection === AppNavigation.Directions.Down &&
        direction === AppNavigation.Directions.Up &&
        (a === prevElement || b === prevElement)
      ) {
        return a === prevElement ? -1 : 1;
      }

      // navigation in one row should have more priority
      if (
        [AppNavigation.Directions.Left, AppNavigation.Directions.Right].includes(direction) &&
        Math.floor(rectA.top) !== Math.floor(rectB.top) &&
        [Math.floor(rectA.top), Math.floor(rectB.top)].includes(Math.floor(activeElementRect.top))
      ) {
        return Math.floor(activeElementRect.top) === Math.floor(rectA.top) ? -1 : 1;
      }

      // navigation in one column should have more priority
      if (
        [AppNavigation.Directions.Up, AppNavigation.Directions.Down].includes(direction) &&
        Math.floor(rectA.left) !== Math.floor(rectB.left) &&
        [Math.floor(rectA.left), Math.floor(rectB.left)].includes(Math.floor(activeElementRect.left))
      ) {
        return Math.floor(activeElementRect.left) === Math.floor(rectA.left) ? -1 : 1;
      }
      const distanceA = this.getDistance(rectA, activeElementRect);
      const distanceB = this.getDistance(rectB, activeElementRect);

      return Math.sign(distanceA - distanceB);
    });
  }
}
