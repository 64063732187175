export enum ElementTestIdentifierScope {
  Common = 'player_common',
  Web = 'player_web',
  SmartTV = 'player_smart_tv',
}

export const TestIdentifierElementMap = {
  [ElementTestIdentifierScope.Common]: {
    root: 'root',
    descriptionLayer: 'descriptionLayer',
    adultContentText: 'adultContentText',
    videoStateControl: 'videoStateControl',
    skipBackwardControl: 'skipBackwardControl',
    skipForwardControl: 'skipForwardControl',
    volumeControl: 'volumeControl',
    settingsControl: 'settingsControl',
    nextEpisodeControl: 'nextEpisodeControl',
    episodesMenuControl: 'episodesMenuControl',
    fullscreenControl: 'fullscreenControl',
    toLiveControl: 'toLiveControl',
  },
  [ElementTestIdentifierScope.Web]: {
    kinomPrimaryButton: 'kinomPrimaryButton',
    contentPrimaryButton: 'contentPrimaryButton',
    pipControl: 'pipControl',
    remotePlayControl: 'remotePlayControl',
    webEpisodesButtonSelect: 'webEpisodesButtonSelect',
    webEpisodesBodyOptions: 'webEpisodesBodyOptions',
  },
  [ElementTestIdentifierScope.SmartTV]: {},
};

export function getTestElementIdentifier<T extends ElementTestIdentifierScope>(
  scope: T,
  key: keyof (typeof TestIdentifierElementMap)[T],
  additionalAlias?: string,
): string | undefined {
  const alias = TestIdentifierElementMap[scope][key] as string;

  if (!alias) {
    return undefined;
  }

  return `${scope}_${alias}${additionalAlias || ''}`;
}
