import { KeyboardHandler, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { inject } from 'vue';

export default function useKeyboardHandler() {
  const keyboard = inject<KeyboardHandler>('handler.keyboard') as KeyboardHandler;

  if (!keyboard) {
    throw new UnexpectedComponentStateError('keyboard');
  }

  return keyboard;
}
