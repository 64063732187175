import { Disposable, UnexpectedComponentStateError } from '@package/sdk/src/core';
import {
  alertService,
  authService,
  deviceService,
  environmentService,
  lifecycleService,
  logger,
  requestService,
  routerService,
  SessionGetters,
  SessionState,
  storageService,
  storeToRefs,
  userService,
  useSessionStore,
} from '@package/sdk/src/smarttv';
import type { AuthService } from '@package/sdk/src/smarttv/services/auth/auth-service';
import type { DeviceService } from '@package/sdk/src/smarttv/services/device/device-service';
import type { EnvironmentService } from '@package/sdk/src/smarttv/services/environment/environment-service';
import type { LifecycleService } from '@package/sdk/src/smarttv/services/lifecycle/lifecycle-service';
import type { AlertService } from '@package/sdk/src/smarttv/services/notifications/alert-service';
import type { RequestService } from '@package/sdk/src/smarttv/services/request-service';
import type { RouterService } from '@package/sdk/src/smarttv/services/router/router-service';
import type { IStorageService } from '@package/sdk/src/smarttv/services/storage/storage-service';
import type { UserService } from '@package/sdk/src/smarttv/services/user/user-service';

import { PlaybackState, RCEventTopic } from '@/services/remote-control/rc-event';
import { RemoteControlActionsController } from '@/services/remote-control/remote-control-actions-controller';
import { RemoteControlSession } from '@/services/remote-control/remote-control-session';

export class RemoteControlService extends Disposable {
  private currentSession: RemoteControlSession | undefined;
  private readonly remoteActionsController: RemoteControlActionsController;

  constructor(
    private readonly storageService: IStorageService,
    private readonly deviceService: DeviceService,
    private readonly requestService: RequestService,
    private readonly routerService: RouterService,
    private readonly alertService: AlertService,
    private readonly authService: AuthService,
    private readonly environmentService: EnvironmentService,
    private readonly userService: UserService,
    private readonly lifecycleService: LifecycleService,
  ) {
    super();

    this.remoteActionsController = new RemoteControlActionsController(routerService, alertService);
  }

  public get session() {
    if (!this.currentSession) {
      // console.error(new UnexpectedComponentStateError('session'));
      return undefined;
    }

    return this.currentSession;
  }

  private disposeSession() {
    if (!this.currentSession) {
      console.error(new UnexpectedComponentStateError('session'));
      return;
    }

    this.currentSession.disconnect();
    this.currentSession.dispose();
    this.currentSession = undefined;
  }

  public createSession(uid: string) {
    const options = { immediateConnection: true, uid };

    this.currentSession = new RemoteControlSession(
      this.storageService,
      this.deviceService,
      this.alertService,
      this.requestService,
      this.environmentService,
      options,
    );

    logger.info('createSession', { uid });

    this.currentSession.addEventListener(RCEventTopic.Paired, this.remoteActionsController.devicePaired);
    this.currentSession.addEventListener(RCEventTopic.Stop, this.remoteActionsController.stop);
    this.currentSession.addEventListener(RCEventTopic.StartContent, this.remoteActionsController.startContent);
    this.currentSession.addEventListener(RCEventTopic.Play, this.remoteActionsController.play);
    this.currentSession.addEventListener(RCEventTopic.Pause, this.remoteActionsController.pause);
    this.currentSession.addEventListener(RCEventTopic.OffsetChange, this.remoteActionsController.seekTo);
    this.currentSession.addEventListener(RCEventTopic.QualityChange, this.remoteActionsController.setQuality);
  }

  public initialize() {
    const createSession = () => {
      const { user } = storeToRefs<SessionState, SessionGetters, unknown>(useSessionStore());

      const uid = user.value.currentDevice.uid;

      this.createSession(uid);
    };

    const onUserLogin = () => {
      createSession();
    };

    const onUserChangedProfile = () => {
      if (!this.session) {
        console.error(new UnexpectedComponentStateError('session'));
        return;
      }

      this.disposeSession();

      createSession();
    };

    const onUserLogout = () => {
      if (!this.session) {
        return console.error(new UnexpectedComponentStateError('session'));
      }

      this.disposeSession();
    };

    this.lifecycleService.onWillShutdown(() => {
      if (!this.session) {
        console.error(new UnexpectedComponentStateError('session'));
        return;
      }

      // Если приложение закрывают, пытаемся успеть выполнить коллбек
      this.session.updateTvState({
        volume: 0,
        state: PlaybackState.Stopped,
        content: undefined,
        offset: 0,
        playlist: undefined,
      });
    });

    this.userService.emitter.on('profile.change', onUserChangedProfile);
    this.authService.emitter.on('user.logout', onUserLogout);
    this.authService.emitter.on('user.login', onUserLogin);
  }
}

export const remoteControlService = new RemoteControlService(
  storageService,
  deviceService,
  requestService,
  routerService,
  alertService,
  authService,
  environmentService,
  userService,
  lifecycleService,
);
