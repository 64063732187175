import { inject } from 'vue';

export default function usePlatform() {
  const isWeb = inject<boolean>('platform.web', false);
  const isSmartTV = inject<boolean>('platform.smart', false);
  const isEmbed = inject<boolean>('platform.embed', false);

  return {
    isWeb,
    isSmartTV,
    isEmbed,
  };
}
