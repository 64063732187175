import { PlayerInstanceConstructorOptions } from '@PLAYER/player/modules/instance/interfaces';

const _default = {
  muted: true,
  autoplay: false,
  loop: false,
  posterSrc: '',
  preload: true,
  autoStartLoad: false,
};

export function validateConfig(config: PlayerInstanceConstructorOptions) {
  const { src, muted, autoplay, loop, posterSrc, preload, projector, autoStartLoad } = config;

  return {
    projector,
    src,
    autoStartLoad: autoStartLoad ?? _default.autoStartLoad,
    muted: muted ?? _default.muted,
    autoplay: autoplay ?? _default.autoplay,
    preload: preload ?? _default.preload,
    loop: loop ?? _default.loop,
    posterSrc: posterSrc ?? _default.posterSrc,
  };
}
