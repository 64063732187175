import type { IStorageService } from '../storage/storage-service';
import { StorageKeys } from '../storage/storage-types';

export enum Onboardings {
  MomentsFeed = 'momentsFeed',
  MomentsLike = 'momentsLike',
  MomentsDislike = 'momentsDislike',
  MomentsSave = 'momentsSave',
  Quiz = 'quiz',
  MyChannel = 'myChannel',
}

export class OnboardingService {
  private readonly quizKey = `${StorageKeys.Onboarding}:${Onboardings.Quiz}`;
  private readonly myChannelKey = `${StorageKeys.Onboarding}:${Onboardings.MyChannel}`;

  constructor(private readonly $storage: IStorageService) {}

  public finishOnboarding(onboarding: Onboardings): void {
    this.$storage.setItem(`${StorageKeys.Onboarding}:${onboarding}`, true);
  }

  public isOnboardingFinished(onboarding: Onboardings): boolean {
    return Boolean(this.$storage.getItem(`${StorageKeys.Onboarding}:${onboarding}`));
  }

  public finishQuiz(): void {
    this.$storage.setItem(this.quizKey, true);
  }

  public isQuizFinished(): boolean {
    const isFinished = (this.$storage.getItem<boolean>(this.quizKey) as boolean) || false;

    return isFinished;
  }

  public finishMyChannel(): void {
    this.$storage.setItem(this.myChannelKey, true);
  }

  public isMyChannelFinished(): boolean {
    const isFinished = (this.$storage.getItem<boolean>(this.myChannelKey) as boolean) || false;

    return isFinished;
  }
}
