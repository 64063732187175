<template>
  <app-button
    :text="primaryButtonText"
    variation="button-primary"
    size="medium"
    @click="getContentAccessKindRedirect(episode?.accessKind)"
  />
</template>

<script setup lang="ts">
import useContentMediaButtonTexts from '@package/content-utils/src/code/content-media-button-texts';
import type { Episode, Offer, Subscription } from '@package/sdk/src/api';
import AppButton from '@PLAYER/player/components/ui/AppButton.vue';
import useContentAccess from '@PLAYER/player/modules/content/use-content-access';
import { translateContentButtonText } from '@PLAYER/player/modules/localization/translate';
import useSession from '@PLAYER/player/modules/session/use-session';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

const props = defineProps<{
  episode?: Episode;
}>();

const { getFullscreenMomentButtonText } = useContentMediaButtonTexts();
const { getContentAccessKindRedirect } = useContentAccess();

const { isAuth, isActiveSubscription, offer, isPartnerSubscription, subscription, hasOfferTrial } = useSession();
const videoConfig = useVideoConfig();

const primaryButtonText = computed(() => {
  return translateContentButtonText(
    getFullscreenMomentButtonText({
      isAuth: isAuth.value,
      isActiveSubscription: isActiveSubscription.value,
      isPartnerSubscription: isPartnerSubscription.value,
      offer: offer.value as Offer,
      subscribePromoTexts: videoConfig['content.subscribePromoTexts'],
      hasTrialOffer: hasOfferTrial.value,
      subscription: subscription.value as Subscription,
    }),
  );
});
</script>
