import { LifecycleService } from '@package/sdk/src/smarttv/services/lifecycle/lifecycle-service';

import { AnalyticService } from './analytics/analytic-service';
import { AuthService } from './auth/auth-service';
import { CatalogService } from './catalog/catalog-service';
import { ChannelsService } from './channels/channels-service';
import { CollectionService } from './collection/collection-service';
import { ContentCacheManager } from './content-cache-manager';
import { DeviceService } from './device/device-service';
import { EnvironmentService } from './environment/environment-service';
import { FeatureService } from './features/feature-service';
import { globalSettings } from './global-settings';
import logger from './logger/logger';
import { MomentService } from './moment/moment-service';
import { AppNavigationService } from './navigation/app-navigation-service';
import { AlertMessageTypes, alertService } from './notifications/alert-service';
import { OffersService } from './offers/offers-service';
import { Onboardings, OnboardingService } from './onboarding/onboarding-service';
import { ParentalService } from './parental/parental-service';
import { PaymentsService } from './payments/payments-service';
import { PlaylistService } from './playlist/playlist-service';
import { QRService } from './qr-service/QRService';
import { QuizService } from './quiz/quiz-service';
import { RequestService } from './request-service';
import { RouterService } from './router/router-service';
import { SearchService } from './search/search-service';
import { IStorageService, StorageService } from './storage/storage-service';
import { TelemetryService } from './telemetry/telemetry-service';
import { UserService } from './user/user-service';

const environmentService = new EnvironmentService();
const storageService: IStorageService = new StorageService();
const deviceService = new DeviceService(storageService, environmentService);
const requestService = new RequestService(deviceService, storageService, environmentService, alertService);

const contentCacheManager = new ContentCacheManager();
const paymentsService = new PaymentsService(environmentService, requestService);
const qrService = new QRService();
const userService = new UserService(requestService, storageService, deviceService, environmentService);

const appNavigationService = new AppNavigationService(environmentService);
const routerService = new RouterService(requestService, appNavigationService);

const authService = new AuthService(requestService, deviceService, userService, storageService, routerService);

const analyticService = new AnalyticService(deviceService, environmentService, routerService);
const catalogService = new CatalogService(requestService, storageService, deviceService);

const lifecycleService = new LifecycleService();

const playlistService = new PlaylistService(requestService);
const channelsService = new ChannelsService(requestService);
const searchService = new SearchService(requestService, deviceService);
const offersService = new OffersService(requestService);

const parentalService = new ParentalService(requestService);
const momentService = new MomentService(requestService, storageService);
const collectionService = new CollectionService(requestService, storageService);
const onboardingService = new OnboardingService(storageService);

const featuresService = new FeatureService(requestService, environmentService);
const quizService = new QuizService(requestService);
const telemetryService = new TelemetryService(storageService);

export {
  AlertMessageTypes,
  alertService,
  analyticService,
  appNavigationService,
  authService,
  catalogService,
  channelsService,
  collectionService,
  contentCacheManager,
  deviceService,
  environmentService,
  featuresService,
  globalSettings,
  lifecycleService,
  logger,
  momentService,
  offersService,
  Onboardings,
  onboardingService,
  parentalService,
  paymentsService,
  playlistService,
  qrService,
  quizService,
  requestService,
  routerService,
  searchService,
  storageService,
  telemetryService,
  userService,
};
