import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';

export enum SmartTvPlayerNavigatableGroupName {
  PlayerControlsHandle = 'player-controls-handle',
  PlayerControlsHandleHover = 'player-controls-handle-hover',
  PlayerControls = 'player-controls',
  PlayerDebug = 'player-debug',
  PlayerRemotePopup = 'player-remote-popup',
  PlayerTimelinePlayback = 'player-timeline-playback',
}

export function useNavigatableMap() {
  const { isKinom } = useProjector();
  const { isWeb } = usePlatform();

  const getNavigationAttribute = (group: SmartTvPlayerNavigatableGroupName) => {
    if (isKinom.value || isWeb) {
      return undefined;
    }

    return group;
  };

  const getLoopAttribute = () => {
    if (isKinom.value || isWeb) {
      return undefined;
    }

    return 'left,right';
  };

  return {
    getNavigationAttribute,
    getLoopAttribute,
  };
}
