<template>
  <div :class="$style.tooltip" role="dialog">
    <app-player-button
      v-for="option in options"
      :key="option.name"
      :class="$style.button"
      :is-selected="option.name === selectedOption?.name"
      :on-click="() => onSelectOption(option)"
      :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerControls)"
    >
      {{ option.name }}
    </app-player-button>
  </div>
</template>

<script setup lang="ts">
import AppPlayerButton from '@PLAYER/player/components/ui/AppPlayerButton.vue';
import { OptionItem } from '@PLAYER/player/modules/video/use-player-settings';
import {
  SmartTvPlayerNavigatableGroupName,
  useNavigatableMap,
} from '@PLAYER/player/versions/smart/modules/smart-navigation/use-navigatable-map';

defineProps<{
  onSelectOption: (option: OptionItem) => void;
  selectedOption?: OptionItem;
  options: OptionItem[];
}>();

const { getNavigationAttribute } = useNavigatableMap();
</script>

<style module lang="scss">
.tooltip {
  position: absolute;
  bottom: 100px;
  display: grid;
  width: 100%;
  background-color: transparent;
  grid-template-columns: 1fr;
  gap: var(--g-spacing-8);
}

.button {
  outline: 0;
  padding: var(--g-spacing-20);

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);
  }
}
</style>
