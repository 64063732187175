export enum RouterPage {
  MainPage = 'MainPage',
  RemotePlayerPage = 'RemotePlayerPage',

  SearchPage = 'SearchPage',

  CatalogPage = 'CatalogPage',

  CollectionPage = 'CollectionPage',

  ChannelsPage = 'ChannelsPage',
  ChannelsPlayerPage = 'ChannelsPlayerPage',

  MyChannelPage = 'MyChannelPage',
  MomentsPage = 'MomentsPage',

  SettingsPage = 'SettingsPage',
  PersonalData = 'PersonalData',
  Subscriptions = 'Subscriptions',
  UserSubscriptions = 'UserSubscriptions',
  SubscriptionInfo = 'SubscriptionInfo',
  HelpContacts = 'HelpContacts',
  Parental = 'Parental',
  ParentalControl = 'ParentalControl',
  ParentalRating = 'ParentalRating',
  ParentalCode = 'ParentalCode',
  ParentalCodeRecover = 'ParentalCodeRecover',

  AuthPage = 'AuthPage',
  LoginPage = 'LoginPage',
  SignInPage = 'SignInPage',
  SignUpPage = 'SignUpPage',
  RecoverCodePage = 'RecoverCodePage',
  RecoverPasswordPage = 'RecoverPasswordPage',

  ProfilesPage = 'ProfilesPage',
  ProfilePage = 'ProfilePage',

  MediaCardPage = 'MediaCardPage',
  MediaCardControls = 'MediaCardControls',
  MediaCardPlayerPage = 'MediaCardPlayerPage',
  MediaCardCollectionsPage = 'MediaCardCollectionsPage',
  MediaCardCollectionsInfoPage = 'MediaCardCollectionsInfoPage',
  MediaCardDescription = 'MediaCardDescription',
  MediaCardPersons = 'MediaCardPersons',
  MediaCardSeriesPage = 'MediaCardSeriesPage',
  MediaCardSimilarPage = 'MediaCardSimilarPage',

  Offers = 'offers',
  OfferInfo = 'offer-info',
  DeviceInfo = 'device-info',

  DebugTvRemotePage = 'debug-tv-remote',
  LogInfo = 'log-info',
  PartnerNoSubscriptionPage = 'PartnerNoSubscriptionPage',
}

export type RouteLocation = {
  name: RouterPage;
};

export type NavigationGuardReturn = boolean | RouteLocation;
