import { UnexpectedComponentStateError } from '../../core';
import { Disposable } from '../../core/lifecycle/disposable';

const CONSTANTS = {
  routerNavigationDebounceTimeoutMs: 250,
  appNavigationThrottleTimeoutMs: 100,
  authCheckTvTokenTimeoutMs: 5000,
  resendOtpCodeTimeoutSeconds: 60,
};

class ConstantsConfigInstance extends Disposable {
  constructor() {
    super();
  }

  public getProperty<T extends keyof typeof CONSTANTS>(key: T): (typeof CONSTANTS)[T] {
    if (!CONSTANTS[key]) {
      throw new UnexpectedComponentStateError(key);
    }

    return CONSTANTS[key];
  }
}

export const ConstantsConfig = new ConstantsConfigInstance();
